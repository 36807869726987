<div>

    <div class="card border border-info border-1">
        <div class="bg-info p-2">
            <h1 class="card-title text-white">Lista de Eventos</h1>
        </div>
        <div class="card-body">
            <div class="container-btn mt-1 mb-3">
                <div>
                    <div ngbDropdown placement="top-end" class="d-inline-block d-flex flex-column gap-2 flex-md-row"
                        style="margin-right: 10px;">
                        <button type="button" class="btn btn-primary" (click)=" reset()">Agregar Evento</button>

                    </div>
                </div>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Global Search" />
            </div>

            <p-table #dt [value]="eventos" [rows]="10"
                [globalFilterFields]="['name','description','camp_start','camp_end','public_price']"
                [(selection)]="selectCapcitacion" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">

                <ng-template pTemplate="header">
                    <tr>
                     
                        <th pSortableColumn="name">Capacitación <p-sortIcon field="training_id"
                                style="font-size: 10px;"></p-sortIcon></th>
                        <th pSortableColumn="description">Fecha de inicio <p-sortIcon field="start"></p-sortIcon></th>
                        <th pSortableColumn="description">Fecha de finalización <p-sortIcon field="start"></p-sortIcon></th>

                        <th pSortableColumn=" camp_end">Activo <p-sortIcon field="Activo"></p-sortIcon></th>
                        <th pSortableColumn=" location">Locación <p-sortIcon field="location"></p-sortIcon></th>
                        <th pSortableColumn=" location">Temporada <p-sortIcon field="location"></p-sortIcon></th>


                            <th></th>
                            <th></th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        
                        <td>
                            {{searchCap(customer.training_id)}}
                        </td>
                        <td>
                            {{customer.start.slice(0,10)}}
                        </td>
                        <td>
                            {{customer.end.slice(0,10)}}
                        </td>
                       
                        <td>
                            <i class="bx bxs-calendar-check" *ngIf="customer.active" style="color: green;"></i> <i class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.active"></i>
                        </td>
                        <td>
                            {{customer.location}}        
                        </td>
                        <td>
                            {{searchTemp(customer.season_id )}}        
                        </td>
                       
                     


                        <td>
                            <button class="btn btn-warning" (click)="update(customer)">Editar</button>
    
                        </td>
                       

                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>





</div>

<p-dialog header="Title" [(visible)]="display"  [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '80vw'}">
    <ng-template pTemplate="header">
        <h2>
            Agregar nueva evento
        </h2>
    </ng-template>
    <div class="containter">
        <div class="col-12">
            <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">
                <div class="row">
                    <div class="col-6 mb-4">
                        <label for="" class="col-sm-12 col-form-label">Capacitación</label>
                        <div class="col-sm-12">
                            <div class="col-12">
                                <select class="form-select" aria-label="Default select example" formControlName="training_id">
                                   <option *ngFor="let item of capacitaciones" [value]="item.id">{{item.name}}</option>
                                
                                  </select>  
                            </div>   
                        </div>
        
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Temporada</label>
                        <div class="col-sm-12">
                            <div class="col-12">
                                <select class="form-select" aria-label="Default select example" formControlName="season_id">
                                   <option *ngFor="let item of temporada" [value]="item.id">{{item.name}}</option>
                                
                                  </select>  
                            </div>   
                        </div>
        
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Fecha de inicio:</label>
                        <div class="col-sm-12">
                            <input type="datetime-local"  class="form-control" formControlName="start">
                        </div>
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Fecha de finalización:</label>
                        <div class="col-sm-12">
                            <input type="datetime-local"  class="form-control"  formControlName="end">
                        </div>
                    </div>
                   
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Lugar</label>
                        <div class="col-sm-12">
                            <input type="url" class="form-control" id=""
                            formControlName="location">
                        </div>
        
                    </div>
                    <div class="col-6 mt-5">
                        <div class="form-check form-switch mx-auto">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="active">
                            <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
                        </div>
                        <div class="form-check form-switch mx-auto">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" checked formControlName="open_enrollment">
                            <label class="form-check-label" for="flexSwitchCheckChecked2">Inscripcion abierta</label>
                        </div>
                    </div>
                   
                </div>
    
               
                <div>
                    <button type="submit" class="btn btn-primary">
                    Guardar
                    </button>
                </div>
    
    
            </form>
        </div>
        
    </div>

    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>


<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '80vw'}">
    <ng-template pTemplate="header">
        <h2>
            Agregar nueva evento
        </h2>
    </ng-template>
    <div class="containter">
        <div class="col-12">
            <form [formGroup]="addTrainingForm" >
                <div class="row">
                    <div class="col-6 mb-4">
                        <label for="" class="col-sm-12 col-form-label">Capacitación</label>
                        <div class="col-sm-12">
                            <div class="col-12">
                                <select class="form-select" aria-label="Default select example" formControlName="training_id">
                                   <option *ngFor="let item of capacitaciones" [value]="item.id">{{item.name}}</option>
                                
                                  </select>  
                            </div>   
                        </div>
        
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Temporada</label>
                        <div class="col-sm-12">
                            <div class="col-12">
                                <select class="form-select" aria-label="Default select example" formControlName="season_id">
                                   <option *ngFor="let item of temporada" [value]="item.id">{{item.name}}</option>
                                
                                  </select>  
                            </div>   
                        </div>
        
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Fecha de inicio:</label>
                        <div class="col-sm-12">
                            <input type="datetime-local"  class="form-control" formControlName="start">
                        </div>
                    </div>
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Fecha de finalización:</label>
                        <div class="col-sm-12">
                            <input type="datetime-local"  class="form-control"  formControlName="end">
                        </div>
                    </div>
                   
                    <div class="col-6  mb-4">
                        <label for="" class="col-sm-12 col-form-label">Lugar</label>
                        <div class="col-sm-12">
                            <input type="url" class="form-control" id=""
                            formControlName="location">
                        </div>
        
                    </div>
                    <div class="col-6 mt-5">
                        <div class="form-check form-switch mx-auto">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="active">
                            <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
                        </div>
                        <div class="form-check form-switch mx-auto">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" checked formControlName="open_enrollment">
                            <label class="form-check-label" for="flexSwitchCheckChecked2">Inscripcion abierta</label>
                        </div>
                    </div>
                   
                </div>
    
               
                <div>
                    <button (click)="keepUpdate()" class="btn btn-primary">
                    Guardar
                    </button>
                </div>
    
    
            </form>
        </div>
        
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()">Guardar</button>
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Eliminar  capacitacion
       </h2> 
    </ng-template>
    <div class="col">
       {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Eliminar</button>
    </ng-template>
</p-dialog>
