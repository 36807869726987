<div *ngIf="!cargando">
    <p-tabView *ngIf="table">
        <p-tabPanel header="Pagos">
            <div>

                <div class="card border border-info border-1">
                    <div class="bg-info p-2">
                        <h1 class="card-title text-white">Usuarios</h1>
                    </div>
                    <div class="card-body">
                        <div class="container-btn mt-1 mb-3">
                            <div>
                                <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                                    <button type="button" class="btn btn-primary" (click)="showDialog()">Añadir Pago</button>

                                </div>
                            </div>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Global Search" />
                        </div>
                        <div style="overflow: auto;">
                            <p-table #dt [value]="listcatalogos" [rows]="5" [rowsPerPageOptions]="[5, 25, 50]"
                                [globalFilterFields]="['Padres','nombreCamper','payment_date','payment_amount','tipoMovimiento','metodosPago']"
                                [(selection)]="selectCatalogos" [rowHover]="true" dataKey="id"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true" [paginator]="true">

                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="Padres" style="min-width: 190px; text-align: center;">
                                            Padre <p-sortIcon field="Padres"
                                                style="font-size: 10px;"></p-sortIcon></th>
                                        <th pSortableColumn="nombreCamper" style="min-width: 150px;text-align: center;">
                                            Nombre del Acampador<p-sortIcon field="nombreCamper"></p-sortIcon></th>
                                        <th pSortableColumn="camp" style="min-width: 150px;text-align: center;">
                                            Camp<p-sortIcon field="camp"></p-sortIcon></th>
                                            <th pSortableColumn="paid" style="min-width: 150px;text-align: center;">
                                                (u'Esta inscrito',)<p-sortIcon field="paid"></p-sortIcon></th>
                                                <th pSortableColumn="payment_date" style="min-width: 150px;text-align: center;">
                                                    Fecha de pago<p-sortIcon field="payment_date"></p-sortIcon></th>
                                                    <th pSortableColumn="payment_amount" style="min-width: 110px;text-align: center;">
                                                        Monto<p-sortIcon field="payment_amount"></p-sortIcon></th>
                                                        <th pSortableColumn="tipoMovimiento" style="min-width: 60px;text-align: center;">
                                                            Tipo de transacion<p-sortIcon field="tipoMovimiento"></p-sortIcon></th>
                                                        <th pSortableColumn="metodosPago" style="min-width: 50px;text-align: center;">
                                                            Metodo de pago<p-sortIcon field="metodosPago"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-customer>
                                    <tr>
                                        <td (click)="update(customer)" style="cursor:pointer; text-align: center;">
                                           {{customer.Padres}}
                                        </td>
                                        <td style="font-size: 12px; font-size: 12px;">
                                            {{customer.nombreCamper}}

                                        </td>
                                        <td style="font-size: 12px; font-size: 12px;">
                                            {{customer.camp}}
                                        </td>
                                        <td style="text-align: center; font-size: 12px;">
                                            <div *ngIf="customer.paid"><i class="bx bx-check-circle"
                                                style="color: green;text-align: center;"></i></div>
                                        <div *ngIf="!customer.paid"><i class="dripicons-cross"
                                                style="color: red;text-align: center;"></i></div>
                                        </td>
                                        <td style="text-align: center; font-size: 12px;">
                                            {{customer.payment_date}}

                                        </td>
                                        <td style="text-align: center; font-size: 12px;" >
                                            {{customer.payment_amount}}
                                        </td>
                                     

                                        <td style="text-align: center;">
                                            
                                            {{customer.tipoMovimiento}}

                                        </td>
                                        <td style="text-align: center;">
                                            {{customer.metodosPago}}

                                        </td>


                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>

                    </div>
                </div>





            </div>

        </p-tabPanel>


    </p-tabView>
    <div *ngIf="!table && !display2">
        <form [formGroup]="formFood">


            <div class="row">
                <div class="col-9">
                    <div class="card p-5">
                        <div class="row mb-4">
                            <label for=""
                                class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control " id=""    [value]="nameParent" disabled="false" (input)="searchparten()">
                                    <button type="button" class="btn btn-primary" (click)="showDialogSearch()" icon="pi pi-info-circle" label="Selecionar  Padre o Tutor">Selecionar  Padre o Tutor</button>
                                </div>


                        </div>
                        <div class="row mb-4">
                            <label for=""
                                class="col-sm-3 col-form-label">Acampador</label>
                                <div class="col-sm-9">
                                    <select bindLabel="value" bindValue="id" formControlName="parent_id"  style="width: 100%;"   class=" form-control" >
                                        <option *ngIf="!resSearch">Por favor Agregue un nombre en el buscador para empezar</option>
                                        <option *ngIf="resSearch">Seleciona un Padre o Tutor</option>
                                        <option *ngFor="let items of selectHijos" [value]="items.tutor_id" > {{items.tutor_name}}</option>
                                    </select>
                                </div>


                        </div>
                    </div>

                </div>
              

                <div class="col-3 ">
                    <div class="card p-5">



                        <div class="btn-footer">
                            <button class="btn btn-secondary btn-lg mt-3" (click)="canelar()"
                                style="width: 90%;">Cancelar</button>
                            <div class="mt-3">
                                <button class="btn btn-info  btn-lg" (click)="prueba()" style="width: 90%;"
                                    *ngIf="!spinner">Guardar</button>
                                <div class="spinner-border" role="status" *ngIf="spinner">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <p-dialog header="Buscador" *ngIf="displayEdit" [(visible)]="displayEdit" position="right" modal="True" [style]="{width: '50vw'}">
                    <div class="row mb-4">
                        <label for=""
                            class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control " id="" formControlName="parent_name" name="parent_name"  (input)="searchparten()">
                                <div class="alert alert-warning" role="alert" *ngIf="!resSearch">        
                                    Por favor Escriba los 3 primeros caracteres del nombre del padre o tutor para inciar la busqueda
                                 </div>
                            </div>
                    </div>
                    <div class="alert alert-primary" role="alert" *ngIf="resSearch">
                       
                                Se encontro un total de {{parent.length}}
                 
                        
                      </div>
                      
                    <div class="row mb-4" >
                        <label for=""
                            class="col-sm-3 col-form-label">Padre o tutor</label>
                            <div class="col-sm-9">
                                <select bindLabel="value" bindValue="id" formControlName="parent_id"  style="width: 100%;"   class=" form-control" >
                                    <option *ngIf="!resSearch">Por favor Agregue un nombre en el buscador para empezar</option>
                                    <option *ngIf="resSearch">Seleciona un Padre o Tutor</option>
                                    <option *ngFor="let items of parent" [value]="items.tutor_id" > {{items.tutor_name}}  {{items.tutor_lastname_father}}  {{items.tutor_lastname_mother}}</option>
                                </select>
                            </div>
                    </div>
                    <p-dialog-footer>
                        <button class="btn btn-primary" (click)="select()">Guardar</button>
                    </p-dialog-footer>	
                </p-dialog>

            </div>
            <p-dialog header="Buscador" *ngIf="displayEdit" [(visible)]="displayEdit" position="right" modal="True" [style]="{width: '50vw'}">
                <div class="row mb-4">
                    <label for=""
                        class="col-sm-3 col-form-label">Buscar Padre o tutor</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control " id="" formControlName="parent_name" name="parent_name"  (input)="searchparten()">
                            <div class="alert alert-warning" role="alert" *ngIf="!resSearch">        
                                Por favor Escriba los 3 primeros caracteres del nombre del padre o tutor para inciar la busqueda
                             </div>
                        </div>
                </div>
                <div class="alert alert-primary" role="alert" *ngIf="resSearch">
                   
                            Se encontro un total de {{parent.length}}
             
                    
                  </div>
                  
                <div class="row mb-4" >
                    <label for=""
                        class="col-sm-3 col-form-label">Padre o tutor</label>
                        <div class="col-sm-9">
                            <select bindLabel="value" bindValue="id" formControlName="parent_id"  style="width: 100%;"   class=" form-control" >
                                <option *ngIf="!resSearch">Por favor Agregue un nombre en el buscador para empezar</option>
                                <option *ngIf="resSearch">Seleciona un Padre o Tutor</option>
                                <option *ngFor="let items of parent" [value]="items.tutor_id" > {{items.tutor_name}}  {{items.tutor_lastname_father}}  {{items.tutor_lastname_mother}}</option>
                            </select>
                        </div>
                </div>
                <p-dialog-footer>
                    <button class="btn btn-primary" (click)="select()">Guardar</button>
                </p-dialog-footer>	
            </p-dialog>
        </form>
    </div>

    <div *ngIf="display2">
        <form [formGroup]="formFood">


            <div class="row">
               
                <div class="col-3 ">
                    <div class="card p-5">
                        <div class="btn-footer">
                            <button class="btn btn-secondary btn-lg mt-3" (click)="cancelarUpdate()"
                                style="width: 90%;">Cancelar</button>
                                <button class="btn btn-secondary btn-lg mt-3" (click)="deletModal()"
                                style="width: 90%;">Eliminar</button>
                            <div class="mt-3">
                                <button class="btn btn-info  btn-lg" (click)="keepUpdate()" style="width: 90%;"
                                    *ngIf="!spinner">Guardar</button>
                                <div class="spinner-border" role="status" *ngIf="spinner">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>

<div class="spinner-border text-primary" role="status" style="margin: 0 auto;" *ngIf="cargando">
    <span class="sr-only">Loading...</span>
</div>

