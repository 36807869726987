<div class="card border border-success border-3">
    <div class="bg-success p-2">
        <h1 class="card-title text-white">Acampadores</h1>

    </div>
    <div class="card-body">
        <div>
            <div class="col text-end">

            </div>
        </div>
        <div class="container-btn">

            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
        </div>

        <div class="scroll-y">
            <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10" [value]="campers"
                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                [globalFilterFields]="['camper_name',
                'tutor_fullname',
                'tutor_email',
                'tutor_cellphone',
                'second_tutor_full_name',
               ' second_tutor_email',
                'second_tutor_cellphone']">

                <ng-template pTemplate="header">
                    <tr>


                        <th pSortableColumn="name" class="camp">
                            <div class="flex justify-content-between align-items-center" style="width: 50px;">
                                Foto
                            </div>
                        </th>
                        <th pSortableColumn="name" class="camp">
                            <div class="flex justify-content-between align-items-center" style="width: 90px;">
                                Nombre
                                <p-sortIcon field="name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="tutor_fullname">
                            <div class="flex justify-content-between align-items-center" style="width: 100px;">
                                Tutor 1
                                <p-sortIcon field="tutor_fullname"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="second_tutor_full_name">
                            <div class="flex justify-content-between align-items-center" style="width: 100px;">
                                Tutor 2
                                <p-sortIcon field="second_tutor_full_name"></p-sortIcon>
                            </div>
                        </th>
                        <th pSortableColumn="precio">
                            <div class="flex justify-content-between align-items-center" style="width: 220px;">
                                Record de consultas
                                <p-sortIcon field="precio"></p-sortIcon>
                            </div>
                        </th>
                        <th style="min-width: 140px;">

                        </th>






                      

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr class="p-selectable-row">

                        <td (click)="navegate(customer.camper_id)">
                            <p-avatar image="http://142.93.12.234:8000/{{customer.camper_photo}}" shape="circle"
                                size="large"></p-avatar>
                        </td>
                        <td (click)="navegate(customer.camper_id)">
                            {{customer.camper_name}}
                        </td>
                        <td>
                           {{customer.tutor_fullname}}<br>
                           {{customer.tutor_email}} <br>
                           {{customer.tutor_cellphone}} 
                        </td>
                        <td>
                            {{customer.second_tutor_full_name}}<br>
                            {{customer.second_tutor_email}} <br>
                            {{customer.second_tutor_cellphone}}
                         </td>
                        
                              

                        <td>

                            <div style="display: flex;flex-wrap: wrap;width: 100%;">
                                <div *ngFor="let item of customer.medical_triages" >
                                    <p-avatar  [image]="'../../../assets/images/medical/' + item[0].value + '.png'" pBadge value="{{item.length}}"
                                    [style]="{'height': '30px',  'width': 'auto','margin': '0px 7px 10px','display':'block'} "
                                    (click)="valores(customer)" (click)="   data(customer.camper_id,item[0].id)"></p-avatar>
                                </div>
                            </div>
                         
                            
                       
                           
                    
     

                        </td>



                        <td>
                            <button class="btn btn-primary" style="width: 100%;" class="btn btn-primary" (click)="navegate(customer.camper_id)">ver perfil</button>
                        </td>


                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">

                </ng-template>
            </p-table>

        </div>


    </div>
</div>
<div class="card border border-danger border-3">
    <div class="bg-danger p-2">
        <h1 class="card-title text-white">Staff</h1>

    </div>
    <div class="card-body">
        <div>
            <div class="col text-end">

            </div>
        </div>


        <div >
            <div class="scroll-y">
                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                    [value]="staffs" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                    [loading]="loading" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="['name','country.name','representative.name','status']">

                    <ng-template pTemplate="header">
                        <tr>


                            <th pSortableColumn="name" class="camp">
                                <div class="flex justify-content-between align-items-center" style="width: 50px;">
                                    Foto
                                </div>
                            </th>
                            <th pSortableColumn="name" class="camp">
                                <div class="flex justify-content-between align-items-center"
                                    style="max-width: 200px; width: 200px;">
                                    Nombre
                                    <p-sortIcon field="name"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="precio">
                                <div class="flex justify-content-between align-items-center" style="width: 100px;">
                                    Tutor 1
                                    <p-sortIcon field="precio"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="precio">
                                <div class="flex justify-content-between align-items-center" style="width: 100px;">
                                    Record de consultas
                                    <p-sortIcon field="precio"></p-sortIcon>
                                </div>
                            </th>






                            <th pSortableColumn="precio">
                                <div class="flex justify-content-between align-items-center" style="width: 80px;">
                                </div>
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                        <tr class="p-selectable-row">

                            <td>
                                <p-avatar image="../../../assets/images/users/avatar-2.jpg" shape="circle"
                                    size="large"></p-avatar>
                            </td>
                            <td>
                                {{customer.staff_full_name}}
                            </td>


                            <td>
                              
                               {{customer.staff_email}}<br>
                                {{customer.staff_contact_cellphone}} </td>
                            <td>
                            </td>
                            <td>
                                <button class="btn btn-primary" class="btn btn-primary">ver perfil</button>
                            </td>


                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">

                    </ng-template>
                </p-table>

            </div>

        </div>


    </div>
</div>



<p-overlayPanel #op [showCloseIcon]="true" [style]="{ width: '200px' }">
    <ng-template pTemplate>
        <div *ngIf="!showSpinner">
            <p>Fecha de atención: </p>
            <p class="fw-bold">{{infoDataCamp.attention_date }}</p>
            <p>Hora de atención: </p>
            <p class="fw-bold">{{infoDataCamp.attention_time }}</p>
            <p>Diagnostico: </p>
            <p class="fw-bold">{{infoDataCamp.diagnostic }}</p>
            <button class="btn btn-primary" (click)="showMaximizableDialog()">Ver Mas</button>
        </div>

        <div *ngIf="showSpinner">
            <div class="spinner-border" role="status">
              </div>
        </div>
       
    </ng-template>
</p-overlayPanel>



<p-dialog  [(visible)]="displayMaximizable" *ngIf="displayMaximizable" [modal]="true" [style]="{width: '60vw'}" [maximizable]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class=" p-5" >
    
        <p-tabView>
            <p-tabPanel header="Consuta inicial">
                <div class="col-12 " style="margin-top: 30px;">
                    <p class="text-center fs-4 fw-bolder">Diagnóstico : Pequeña contusion en la parte del cranio por
                        caida
                        de niños corriendo</p>
    
                    <p class="text-center fs-4 fw-bold mt-4">Medico Responsable : {{infoDataCamp.doctor}}</p>
    
                </div>
                <div>
                    <div class="row mt-5">
                        <div class="col-6 fs-4">
                            <p> Fecha y hora de atencion</p>
    
                        </div>
                        <div class="col-6 fs-4 ">
                            <p>{{infoDataCamp.attention_date}} {{infoDataCamp.attention_time}} </p>
                        </div>
    
                        <hr>
                    </div>
    
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> Descripcion basica para personal no medico</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.description}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> Descripción del evento en palabras del camper </p>
    
                        </div>
                        <div class="col-6 fs-6 ">
                            <p>{{infoDataCamp.event_description}}</p>
    
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> ¿Quién autorizó el medicamento?</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{getAuthorizationName(infoDataCamp.medication_authorization)}}</p>
                                            </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>tratamientos </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.administered_medications}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Restricciones o medidas dentro del campamento</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.camp_restriction}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Recomendaciones para seguimiento posterior al campamento</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.medical_monitoring}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Comentario de la consulta </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.medical_comment}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Comentario solo para medicos y staff
                            </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{infoDataCamp.comment}}</p>
                        </div>
    
                        <hr>
                    </div>
    
                </div>            </p-tabPanel>
            <p-tabPanel *ngFor="let seguimiento of infoDataCamp.tracing_visits" header="Consulta de seguimiento">
                <div class="col-12 " style="margin-top: 30px;">
                    <p class="text-center fs-4 fw-bolder">Diagnóstico : Pequeña contusion en la parte del cranio por
                        caida
                        de niños corriendo</p>
    
                    <p class="text-center fs-4 fw-bold mt-4">Medico Responsable : {{seguimiento.doctor}}</p>
    
                </div>
                <div>
                    <div class="row mt-5">
                        <div class="col-6 fs-4">
                            <p> Fecha y hora de atencion</p>
    
                        </div>
                        <div class="col-6 fs-4 ">
                            <p>{{seguimiento.attention_date}} {{seguimiento.attention_time}} </p>
                        </div>
    
                        <hr>
                    </div>
    
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> Descripcion basica para personal no medico</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.description}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> Descripción del evento en palabras del camper </p>
    
                        </div>
                        <div class="col-6 fs-6 ">
                            <p>{{seguimiento.event_description}}</p>
    
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p> ¿Quién autorizó el medicamento?</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{getAuthorizationName(seguimiento.medication_authorization)}}</p>
                                            </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>tratamientos </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.administered_medications}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Restricciones o medidas dentro del campamento</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.camp_restriction}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Recomendaciones para seguimiento posterior al campamento</p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.medical_monitoring}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Comentario de la consulta </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.medical_comment}}</p>
                        </div>
    
                        <hr>
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 fs-6">
                            <p>Comentario solo para medicos y staff
                            </p>
    
                        </div>
                        <div class="col-6 fs-6">
                            <p>{{seguimiento.comment}}</p>
                        </div>
    
                        <hr>
                    </div>
    
                </div>   
            </p-tabPanel>
          
        </p-tabView>
    </div>

</p-dialog>