<div>

    <div class="card border border-info border-1">
        <div class="bg-info p-2">
            <h1 class="card-title text-white">Lista de capacitaciones</h1>
        </div>
        <div class="card-body">
            <div class="container-btn mt-1 mb-3">
                <div>
                    <div ngbDropdown placement="top-end" class="d-inline-block d-flex flex-column gap-2 flex-md-row"
                        style="margin-right: 10px;">
                        <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar nueva
                            capacitacion</button>

                    </div>
                </div>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Global Search" />
            </div>

            <p-table #dt [value]="capacitaciones" [rows]="10"
                [globalFilterFields]="['name','description','camp_start','camp_end','public_price']"
                [(selection)]="selectCapcitacion" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">

                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="name"> Capacitacion <p-sortIcon field="camp"
                                style="font-size: 10px;"></p-sortIcon></th>
                        <th pSortableColumn="description">Fecha de inicio <p-sortIcon field="sede"></p-sortIcon></th>
                        <th pSortableColumn=" camp_end">Fecha de finalización <p-sortIcon field="Activo"></p-sortIcon>
                        </th>
                        <th pSortableColumn=" camp_end">Temporada <p-sortIcon field="Activo"></p-sortIcon></th>
                        <th pSortableColumn=" camp_end">Activo <p-sortIcon field="Activo"></p-sortIcon></th>


                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>

                        <td>
                            {{customer.training}}
                        </td>
                        <td>
                            {{customer.start.substring(0,11)}}
                        </td>
                        <td>
                            {{customer.end.substring(0,11)}}
                        </td>
                        <td>
                            {{customer.season}}
                        </td>
                        <td>

                            <i class="bx bxs-calendar-check" *ngIf="customer.active" style="color: green;"></i> <i
                                class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.active"></i>

                        </td>




                        <td>
                            <button class="btn btn-warning" (click)="update(customer)">Editar</button>

                        </td>
                      <td>
                            <button class="btn btn-danger"
                                (click)="deletModal(customer.name,customer.id)">Eliminar</button>
                        </td>
                    
                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>





</div>

<p-dialog header="Title" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Agregar nueva evento
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Capacitaciones</label>
                <div class="col-sm-9">
                    <select name="" id="" formControlName="training_id" class="form-control">
                        <option value="" *ngFor="let item of capacitacionesName" [value]="item.id" style="width: 100%;"
                            class="form-control">{{item.name}}</option>
                    </select>
                </div>

            </div>

            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Fecha de inicio</label>
                <div class="col-sm-9">
                    <input input type="datetime-local" placeholder="yyyy-mm-dd" class="form-control" id=""
                        formControlName="start">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Fecha de finalización</label>
                <div class="col-sm-9">
                    <input type="datetime-local" placeholder="yyyy-mm-dd" class="form-control" id=""
                        formControlName="end">
                </div>
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Lugar</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="location">
                </div>
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Temporada</label>
                <div class="col-sm-9">
                    <select name="" id="" formControlName="season_id" class="form-control">
                        <option value="" *ngFor="let item of temporada" [value]="item.id" style="width: 100%;"
                            class="form-control">{{item.name}}</option>
                    </select>
                </div>

            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" checked
                    formControlName="open_enrollment">
                <label class="form-check-label" for="flexSwitchCheckChecked2">Inscripcion abierta</label>
            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked
                    formControlName="active">
                <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
            </div>




        </form>
    </div>

    <ng-template pTemplate="footer">
        <div class="button">
            <button type="submit" class="btn btn-primary p-3" (click)="onSave()" *ngIf="!showButton">
                Guardar
            </button>
            <div class="spinner-border text-primary" role="status" *ngIf="showButton">
                
              </div>
        </div>
    </ng-template>
</p-dialog>


<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Editar Evento
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Capacitaciones</label>
                <div class="col-sm-9">
                    <select name="" id="" formControlName="training_id" class="form-control">
                        <option value="" *ngFor="let item of capacitacionesName" [value]="item.id" style="width: 100%;"
                            class="form-control">{{item.name}}</option>
                    </select>
                </div>

            </div>

            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Fecha de inicio</label>
                <div class="col-sm-9">
                    <input input type="datetime-local" class="form-control" id="" formControlName="start">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Fecha de finalización</label>
                <div class="col-sm-9">
                    <input type="datetime-local" class="form-control" id="" formControlName="end">
                </div>
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Lugar</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="location">
                </div>
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12col-form-label">Temporada</label>
                <div class="col-sm-9">
                    <select name="" id="" formControlName="season_id" class="form-control">
                        <option value="" *ngFor="let item of temporada" [value]="item.id" style="width: 100%;"
                            class="form-control">{{item.name}}</option>
                    </select>
                </div>

            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" checked
                    formControlName="open_enrollment">
                <label class="form-check-label" for="flexSwitchCheckChecked2">Inscripcion abierta</label>
            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked
                    formControlName="active">
                <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
            </div>




        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()" *ngIf="!showButton">Eliminar</button>
        <div class="spinner-border text-primary" role="status" *ngIf="showButton">
                
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Eliminar divisa
        </h2>
    </ng-template>
    <div class="col">
        {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
        </div>


    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Guardar</button>
    </ng-template>
</p-dialog>