<div>

    <div class="card border border-info border-1">
        <div class="bg-info p-2">
            <h1 class="card-title text-white">Lista de eventos</h1>
        </div>
        <div class="card-body">
            <div class="container-btn mt-1 mb-3">
                <div>
                    <div ngbDropdown placement="top-end" class="d-inline-block d-flex flex-column gap-2 flex-md-row"
                        style="margin-right: 10px;">
                        <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar Evento
                            </button>

                    </div>
                </div>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Global Search" />
            </div>

            <p-table #dt [value]="capacitaciones" [rows]="10"
                [globalFilterFields]="['name','description','camp_start','camp_end','public_price']"
                [(selection)]="selectCapcitacion" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">

                <ng-template pTemplate="header">
                    <tr>
                     
                        <th pSortableColumn="name">Nombre del evento <p-sortIcon field="camp"
                                style="font-size: 10px;"></p-sortIcon></th>
                        <th pSortableColumn="description">Descripcion <p-sortIcon field="sede"></p-sortIcon></th>
                        <th pSortableColumn=" camp_end">Activo <p-sortIcon field="Activo"></p-sortIcon></th>
                            <th></th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        
                        <td>
                            {{customer.name}}
                        </td>
                        <td>
                            {{customer.description}}
                        </td>
                        <td>
                            <i class="bx bxs-calendar-check" *ngIf="customer.active" style="color: green;"></i> <i class="bx bxs-calendar-x" style="color: red;" *ngIf="!customer.active"></i>
                        </td>
                       
                     


                        <td>
                            <button class="btn btn-warning" (click)="update(customer)">Editar</button>
    
                        </td>
                        <td>
                          <!--   <button class="btn btn-danger" (click)="deletModal(customer.name,customer.id)">Eliminar</button> -->
                        </td>

                    </tr>
                </ng-template>

            </p-table>
        </div>
    </div>





</div>

<p-dialog header="Title" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h2>
            Agregar nueva capacitacion
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Nueva Capacitacion</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="name">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Imagen de la
                    capacitacion</label>
                <div class="col-sm-9">
                    <input type="file" class="form-control" id="" (change)="subiendo($event)" accept="image/jpeg">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Descripción</label>
                <ckeditor class="mt-4" [editor]="Editor" data="" formControlName="description">
                </ckeditor>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">URL</label>
                <div class="col-sm-9">
                    <input type="url" class="form-control" id=""
                        formControlName="url">
                </div>

            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="active">
                <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
            </div>
            <div>
               
            </div>


        </form>
    </div>

    <ng-template pTemplate="footer">
        <button type="submit" class="btn btn-primary">
            Guardar
            </button>
    </ng-template>
</p-dialog>


<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '60vw'}">
    <ng-template pTemplate="header">
        <h2>
            Editar  capacitacion
        </h2>
    </ng-template>
    <div class="col">
        <form [formGroup]="addTrainingForm" (ngSubmit)="onSave()">

            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Nueva Capacitacion</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="name">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Imagen de la
                    capacitacion</label>
                <div class="col-sm-9">
                    <input type="file" class="form-control" id="" (change)="subiendo($event)">
                </div>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">Descripción</label>
                <ckeditor class="mt-4" [editor]="Editor" data="" formControlName="description">
                </ckeditor>

            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-12 col-form-label">URL</label>
                <div class="col-sm-9">
                    <input type="url" class="form-control" id=""
                        formControlName="url">
                </div>

            </div>
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked formControlName="active">
                <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
            </div>
            


        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()">Guardar</button>  
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Eliminar  capacitacion
       </h2> 
    </ng-template>
    <div class="col">
       {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="delet()">Eliminar</button>
    </ng-template>
</p-dialog>
