import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GroupingService } from './grouping.service';
import { AdminService } from '../grouping-admi/admin.service';
import { AdminTipoAgrupacionesComponent } from '../admin-tipo-agrupaciones/admin-tipo-agrupaciones.component';
import { AdmitipoAgrupacionesService } from '../admin-tipo-agrupaciones/tipo-agrupacion.service';
import { switchMap } from 'rxjs/operators';
import { element } from 'protractor';



@Component({
  selector: 'app-grouping',
  templateUrl: './grouping.component.html',
  styleUrls: ['./grouping.component.scss']
})
export class GroupingComponent  {
  datos= [{agrupación:"Camping",tipo:"Autobús", editar:'<a class=" btn btn-success">Editar</a>'}, {agrupación:"Camping2",tipo:"Autobús", editar:'<a class=" btn btn-success">Editar</a>'}]
  rows = [];
  temp = [];

  display4= false;
  
  breadCrumbItems: Array<{}>;
  listcatalogos:any ;
  spinner = false;
  modalVista :boolean= true;
  userGridData:any=[];
  selected;
  display3 = false;
  listCampers=[];
  userForm: FormGroup;
  submitted = false;
  items: FormArray;
  idCamp =0;
  tipoAgrupacion:any =[]
  grupos:any =[];
  selectGrupos = "";
  capMax = 0;
  capEdit = 0;
  edintGroup:any;
  groupingsList: string[] = [];


  // Select2 Dropdown
  selectValue: string[];
  selecType:any =0;
  selectCatalogos:any=[]

  columns = [{ prop: 'agrupación' }, { name: 'Tipo' }, { name: 'Editar' } ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('content')content:ElementRef;
  listGruposImscritos=[];


  ColumnMode = ColumnMode;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,private router:ActivatedRoute,private grouping:GroupingService,private listGrouping:AdminService, private listTypeAgrup:AdmitipoAgrupacionesService) {
    this.rows=this.datos;
    this.temp=this.datos;


    this.listTypeAgrup.getAgrupaciones().pipe(
      switchMap((res: any) => {
        this.tipoAgrupacion = res;
        return this.listGrouping.getAgrupaciones();
      })
    ).subscribe((res: any) => {
      this.grupos   = res;
      console.log(this.grupos,'grupos');
      this.grupos = this.grupos.filter(item => item.is_active === true);
      this.grupos.forEach(element => {
       element.nameTipo = this.filterType( element.grouping_type_id );
       element.nameComplet =  element.nameTipo  + " | " + element.name
      });
      this.getGruposInscritos();

    });
    this.router.params.subscribe((res)=>{
      this.idCamp= res.id;
      this.grouping.getCamper(this.idCamp).subscribe((res:any)=>{
        console.log(res);
        this.listCampers = res.data;
     
      })
  })
   }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      designation: ['', [Validators.required]]
    });
  }

  filterType (id){

  let b =   this.tipoAgrupacion.find(item => item.id ==id);
    
  return b.name
  }
 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
 
  openModal(content: any) {
    this.modalService.open(content);
    console.log(content);
    
  }

  openXl(content) {
		this.modalService.open(content, { size: 'xl' });
	}

  saveUser() {
    if (this.userForm.valid) {
      const name = this.userForm.get('name').value;
      const email = this.userForm.get('email').value;
      const designation = this.userForm.get('designation').value;
       this.userGridData.push({
         id: this.userGridData.length + 1,
         name,
         email,
         designation,
         projects: this.selected
       })
       this.modalService.dismissAll()
    }
    this.submitted = true
  }
  dateId(id){
    let b = id
  }


  hasGrouping(item: any, grouping: string): boolean {
    return item.groupings ? item.groupings.split(',').includes(grouping) : false;
  }
  getGruposInscritos(){
    // Obtener los grupos inscritos
    this.grouping.getGruposInscritos(this.idCamp).subscribe((res: any) => {
      this.listGruposImscritos = res.data;
      this.listGruposImscritos.forEach((item: any) => {
        item.nameCample = item.type + " | " + item.capacity; 
      });
    });
  
    // Obtener los campers y generar groupingsList
    this.grouping.getCamper(this.idCamp).subscribe((res: any) => {
      console.log(res);
      this.listCampers = res.data;
  
      // Generar lista única de agrupaciones asegurando la separación correcta por comas
      this.groupingsList = Array.from(new Set(
        this.listCampers
          .filter(item => item.groupings) // Filtrar los que tienen 'groupings'
          .map(item => item.groupings.split(',').map(group => group.trim())) // Separar por comas y eliminar espacios
          .reduce((acc, val) => acc.concat(val), []) // Reemplazo de .flat()
      ));
    });
  }

  createGroup(){
    let a = {
      "maximum_capacity": this.capMax,
      "camp_id": this.idCamp,
      "grouping_id": this.selectGrupos
    }
    this.grouping.createGroup(a).subscribe((res:any)=>{
      this.modalService.dismissAll(this.content);

      this.listTypeAgrup.getAgrupaciones().pipe(
        switchMap((res: any) => {
          this.tipoAgrupacion = res;
          return this.listGrouping.getAgrupaciones();
        })
      ).subscribe((res: any) => {
        this.grupos   = res;
        console.log(this.grupos,'grupos');
        this.grupos = this.grupos.filter(item => item.is_active === true);
        this.grupos.forEach(element => {
         element.nameTipo = this.filterType( element.grouping_type_id );
         element.nameComplet =  element.nameTipo  + " | " + element.name
        });
        this.getGruposInscritos();
      });
      this.selectGrupos ="0";
      this.capMax = 0;
    })
  }

  changeGrups(id:any){
    console.log(id);
    this.selecType = id.id
    
      this.grouping.getCampersInscritos(id.id).subscribe((res:any)=>{
        console.log(res);
        this.listcatalogos = res;
        
      })
  }
  EditchangeGrups(id:any){
    console.log(id);
    
    this.edintGroup = id;
    this.edintGroup.maximum_capacity = this.capEdit;

    const capEdit2 :any= id.capacity;

    let valorDespuesDeSlash: any = capEdit2.split('/')[1];
    valorDespuesDeSlash  = Number(valorDespuesDeSlash);
    this.capEdit = valorDespuesDeSlash;
    this.edintGroup.maximum_capacity = this.capEdit;

  }
  saveEdite (){
    this.edintGroup.maximum_capacity =this.capEdit
    this.edintGroup.camp_id = this.idCamp;
    this.spinner = true;
    this.grouping.editraCapcidadMaxima(this.edintGroup.id,this.edintGroup).subscribe((res:any)=>{
     

      this.listTypeAgrup.getAgrupaciones().pipe(
        switchMap((res: any) => {
          this.tipoAgrupacion = res;
          this.spinner = false;
            this.display4 = false
          return this.listGrouping.getAgrupaciones();
        })
      ).subscribe((res: any) => {
        this.grupos   = res;
        console.log(this.grupos,'grupos');
        this.grupos = this.grupos.filter(item => item.is_active === true);
        this.grupos.forEach(element => {
         element.nameTipo = this.filterType( element.grouping_type_id );
         element.nameComplet =  element.nameTipo  + " | " + element.name
        });
        this.getGruposInscritos();
      });
      this.selectGrupos ="0";
      this.capMax = 0;
    })
  }
  saveGrouping(){
    console.log(this.selectCatalogos);
    let b = [];
    this.spinner = true;
    this.selectCatalogos.forEach((element:any)=>{
      b.push({"camper_id":element.id,"grouping_camp_id":this.selecType})
    })

    this.grouping.campersInscritos(b).subscribe((res:any)=>{
      console.log(res,'se isncribio correctamenta');
      this.listTypeAgrup.getAgrupaciones().pipe(
        switchMap((res: any) => {
          this.tipoAgrupacion = res;
          return this.listGrouping.getAgrupaciones();
        })
      ).subscribe((res: any) => {
        this.grupos   = res;
        console.log(this.grupos,'grupos');
        this.grupos = this.grupos.filter(item => item.is_active === true);
        this.grupos.forEach(element => {
         element.nameTipo = this.filterType( element.grouping_type_id );
         element.nameComplet =  element.nameTipo  + " | " + element.name
        });
        this.getGruposInscritos();
        this.spinner = false;
        this.display3 = false

      });
    })
    
  }

  resetGroupingU(data){
    if (data === null) {
      return null;
    } else {
      return data.split(',');
    }
  }

  customSort(event: any) {
    event.data.sort((a: any, b: any) => {
      const grouping = event.field; // El nombre de la agrupación que se está ordenando
      const aHasGroup = this.hasGrouping(a, grouping);
      const bHasGroup = this.hasGrouping(b, grouping);

      // Comparar si tiene la agrupación (con palomita)
      return (aHasGroup === bHasGroup) ? 0 : aHasGroup ? -1 : 1;
    });
  }

}
