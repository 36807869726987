<div class="container-fluid" *ngIf="cargando && !showTable">
  <div class="row">
    <div class="col-12">
      <div class="card border border-warning border-3" >
      
        <div class="bg-warning p-2" (click)="headerInfo=!headerInfo" style="cursor: pointer;">
          <h1 class="card-title text-white">{{infoCamp.name}}</h1>
          
        </div>
        <div *ngIf="headerInfo">
        <div class="conatiner-info-camp mt-3">
          <div>
            <p class="fw-bold">  Fechas <i class="fas fa-calendar-alt"> : </i> </p>
          </div>
          <div>
            <p>  {{infoCamp.start.substring(0,10)}} - {{infoCamp.end.substring(0,10)}}</p>
          </div>
        </div>
        <div class="conatiner-info-camp">
          <div>
            <p class="fw-bold"> Sede <i class="fas fa-map"> </i> :  </p>
          </div>
          <div>
            <p>  {{infoCamp.location_id}} </p>
          </div>
        </div>

        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold"> Cobertura Póliza <i class="bx bx-plus-medical"> </i> :  </p>
          </div>
          <div>
            <p>  {{infoCamp.insurance}} </p>
          </div>
        </div>

        <div class="conatiner-info-camp">
          <div>
            <p class="fw-bold"> Salida <i class="bx bxs-time"></i> : </p>
          </div>
          <div>
            <p>  {{infoCamp.start.substring(0,10)}} </p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold"> Regreso <i class="bx bxs-time"> </i> : </p>
          </div>
          <div>
            <p>  {{infoCamp.end.substring(0,10)}} </p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold"> Punto de reunion <i class="fas fa-map-marked-alt"> </i> :</p>
          </div>
          <div>
            <p>  {{infoCamp.venue}} </p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Fotos del camp <i class="bx bxs-camera-plus"> </i>:  </p>
          </div>
          <div>
            <p>  <a href="{{infoCamp.photo_url}}">Ver fotos</a> </p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Contraseña: <i class="bx bx-key"> </i> : </p>
          </div>
          <div>
            <p>{{infoCamp.photo_password}}</p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Página del camp <i class="bx bx-world"> </i> : </p>
          </div>
          <div>
            <p> {{infoCamp.url}}</p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Mensaje especial <i class="bx bxs-message-alt"></i> : </p>
          </div>
          <div>
            <p> {{infoCamp.special_message}}</p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Precio Público <i class="far fa-money-bill-alt"> </i> : </p>
          </div>
          <div>
            <p> {{infoCamp.public_price}}</p>
          </div>
        </div>
        <div class="conatiner-info-camp ">
          <div>
            <p class="fw-bold">Mensaje para admin <i class="bx bxs-message-alt"> </i> : </p>
          </div>
          <div>
            <p> {{infoCamp.special_message_admin}}</p>
          </div>
          
        </div>
       
        
        
        
         
      </div>
      <div class="col-12 conatiner-info-camp mb-4" *ngIf="rol ==2 && (user_admin==true)">
        <button class="btn btn-primary" (click)="update()">Editar</button>
      </div>
    </div>
      
      
      <div class="card border border-info border-3" >
      
          <div class="bg-info p-2" (click)="headerCampers=!headerCampers" style="cursor: pointer;">
            <h1 class="card-title text-white">Acampadores</h1>
  
          </div>
          
          <div class="card-body" *ngIf="headerCampers">
            <div>
              <div class="col text-end">
  
              </div>
            </div>
            <div class="container-btn">
              <div>
                <div ngbDropdown placement="top-end" class="d-inline-block">
                  <button type="button" class="btn btn-outline-primary" id="dropdownBasic2"
                    ngbDropdownToggle>Reportes</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem  (click)="reporteGeneral()">General</button>
                    <button ngbDropdownItem (click)="reporteDatosGenerales()">Reporte Seguro Vs Accidentes</button>
                    <button ngbDropdownItem (click)="reporteDatosContactos()">Datos de contactos</button>
                    <button ngbDropdownItem (click)="reporteMedical()">Datos medicos</button>
                    <button ngbDropdownItem (click)="reporteComida()">Datos comidas prohibidos </button>
                    <button ngbDropdownItem (click)="ReporteExtras()">Cargos extras </button>



                  </div>
                </div>
                <div ngbDropdown placement="top-end" class="d-inline-block">
                  <button type="button" class="btn btn-outline-primary" id="dropdownBasic2"
                    ngbDropdownToggle>Credenciales</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem>Exel</button>
                    <button ngbDropdownItem>Fotos</button>
                  </div>
                </div>
                <div ngbDropdown placement="top-end" class="d-inline-block">
                  <button type="button" class="btn btn-outline-primary" id="dropdownBasic2"
                    ngbDropdownToggle>Pulseras</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="pulserac11()">"1" x 11" (ZT-230)</button>
                    <button ngbDropdownItem (click)="pulseras8()" >8 x hoja</button>
                  </div>
                </div>
               
              </div>
             
                <div>

                  <div ngbDropdown placement="top-end" class="d-inline-block"  style="margin-right: 10px;">
                    <button type="button" class="btn btn-success" id="dropdownBasic2"
                      ngbDropdownToggle>Modulos</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                        <button ngbDropdownItem (click)="puntosControl()">Punto de control</button>
                        <button ngbDropdownItem (click)="medicos()">Medicos</button>
                        <button ngbDropdownItem (click)="Agrupaciones()">Agrupaciones</button>

      
                      </div>
                   
                  </div>
                 
                </div>
            </div>

            <div class="container mt-3">
              <div class="centre-derecha">
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
              </div>
             
            </div>
            <div class=" scroll-y">
            <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true"  [value]="listCampers" 
              [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" 
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
              [globalFilterFields]="['camper_full_name','camper_birthday','tutor_full_name','second_tutor_full_name']">
  
              <ng-template pTemplate="header">
                <tr>
  
                  <th pSortableColumn="name" >
                    <div >
                      Foto
                    </div>
                  </th>
                  <th pSortableColumn="camper_full_name" style=" min-width: 200px;">
                    <div >
                      Nombre
                      <p-sortIcon field="camper_full_name"></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="record.n" style=" min-width: 150px;">
                    <div>
                      Record
                      <p-sortIcon field="record.n"></p-sortIcon>
                    </div>
                  </th>
                  <th pSortableColumn="camper_birthday" style=" min-width: 140px;">
                    <div >
                      Fecha de nacimiento
                      <p-sortIcon field="camper_birthday"></p-sortIcon>
                    </div>
                  </th>
               
                  <th pSortableColumn="camper_total_balance" style=" min-width: 100px;">
                    <div>
                      Saldo
                      <p-sortIcon field="camper_total_balance"></p-sortIcon>
                    </div>
                  </th>
  
                  <th pSortableColumn="tutor_full_name" style=" min-width: 100%;">
                    <div>
                      Tutor 1
                      <p-sortIcon field="tutor_full_name"></p-sortIcon>
   
                    </div>
                  </th>
                  <th pSortableColumn="status" style=" min-width: 100%;">
                    <div>
                      Tutor 2
                      <p-sortIcon field="sede"></p-sortIcon>
   
                    </div>
                  </th>
                  <th pSortableColumn="precio">
                    <div  style="width: 140px;">
                    </div>
                  </th>
  
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-customer>
                <tr class="p-selectable-row">
  
                  <td>
                    <p-avatar [image]="'http://142.93.12.234:8000/'+customer.photo_url" shape="circle" size="large"></p-avatar>
                  </td>
                  <td (click)="vercamper(customer.camper_id)">
                    {{customer.camper_full_name}}  <i class="bx bx-conversation" style="color: rgb(147, 147, 224);" *ngIf="customer.camper_comments>0" pTooltip="{{customer.camper_commentss}}"></i> <i class="bx bx-plus-medical"  style="color: rgb(228, 32, 26);"  pTooltip="Solicita llamada" *ngIf="customer.camper_doctor_precall== true"></i>
                  </td>
                  <td>
  
                    <p-badge [value]="customer.camper_total" pTooltip="Total" tooltipPosition="top"></p-badge>
                    <p-badge [value]="customer.camper_attended" pTooltip="Camps anteriores" tooltipPosition="top" severity="danger"
                      severity="danger"></p-badge>
                    <p-badge [value]="customer.camper_attend" pTooltip="Proximos camps" tooltipPosition="top"
                      severity="success" severity="success"></p-badge>
  
  
                  </td>
                  <td>
  
                    {{customer.camper_birthday}}<i class="bx bx-cake" *ngIf="cumpleanosEnRango(customer.camper_birthday)"></i>
                    <br>
                   
  
                  </td>
                  <td (click)="linkPagos(customer.camper_id)">
                   {{ customer.camper_total_balance | currency}}
                    <br>
                    <p-tag value="Pagado" severity="success"  [rounded]="true" *ngIf=" customer.camper_total_balance==0"></p-tag>
                    <p-tag value="Pendiente" severity="warning"  [rounded]="true" *ngIf=" customer.camper_total_balance!=0"></p-tag>

                  </td>
                  <td>
                   {{customer.tutor_full_name}} <br>
                    {{customer.tutor_email}}
                  </td>
                  <td>
                    {{customer.second_tutor_full_name}} <br>
                     {{customer.second_tutor_email}}
                   </td>
  
                  <td>
                    <button class="btn btn-primary" class="btn btn-primary"  (click)="vercamper(customer.camper_id)">ver acampador</button>
                  </td>
  
  
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
  
              </ng-template>
            </p-table>

        </div>
        </div>
      </div>

      <div class="card border border-success border-3" *ngIf="rol ==2 && (user_admin==true || user_coordinator==true)">
        <div class="bg-success p-2" (click)="headerStaffConf=!headerStaffConf" style="cursor: pointer;">
          <h1 class="card-title text-white">Staff confirmado</h1>

        </div>
        <div class="card-body" *ngIf="headerStaffConf">
          <div>
            <div class="col text-end">

            </div>
          </div>
          <div class="container-btn">
            <div>
              <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                <button type="button" class="btn btn-primary" id="dropdownBasic2"
                  ngbDropdownToggle>Reportes</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                  <button ngbDropdownItem>staff</button>
                
                </div>
              </div>
              <div ngbDropdown placement="top-end" class="d-inline-block"  style="margin-right: 10px;">
                <button type="button" class="btn btn-success" id="dropdownBasic2"
                  ngbDropdownToggle>Credenciales de staff</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                  <button ngbDropdownItem>Exel</button>
                  <button ngbDropdownItem>Fotos</button>
                </div>
              </div>
              <div ngbDropdown placement="top-end" class="d-inline-block"  style="margin-right: 10px;">
                <button type="button" class="btn btn-primary" (click)="modalConfir = !modalConfir">Selecciona rol</button>
                
              </div>
            </div>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>

          <div class="scroll-y">
            <p-table #dt [(selection)]="selectedCustomers" dataKey="staff_id"  [rowHover]="true" [rows]="10" [value]="listStaffConfirm"
            [showCurrentPageReport]="true"  [loading]="loading"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0" dataKey="staff_id"
            [globalFilterFields]="['name','country.name','representative.name','status']">

            <ng-template pTemplate="header">
              <tr>
                <th>
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th pSortableColumn="name" class="camp">
                  <div class="flex justify-content-between align-items-center" style="width: 50px;">
                    Foto
                  </div>
                </th>
                <th pSortableColumn="name" class="camp">
                  <div class="flex justify-content-between align-items-center" style="max-width: 200px; width: 200px;">
                    Nombre
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Fecha de nacimiento
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Telefono celulares
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Correos
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="record.n" class="record">
                  <div class="flex justify-content-between align-items-center" style="min-width: 70px; width: 90px;">
                    Record
                    <p-sortIcon field="record.n"></p-sortIcon>
                  </div>
                </th>
              
             
                <th pSortableColumn="sede" style="width: 8rem">
                  <div class="flex justify-content-between align-items-center">
                    Roles
                    <p-sortIcon field="sede"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 80px;">
                  </div>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
              <tr class="p-selectable-row">
                <td>
                  <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                </td>
                <td>
                  <p-avatar [image]="'http://142.93.12.234:8000/'+customer.staff_photo" shape="circle" size="large"></p-avatar>
                </td>
                <td>
                  {{customer.staff_full_name}}
                </td>
               
                <td>
                  {{customer.staff_birthday}}
                  <br>

                </td>
                <td>
                 {{customer.staff_cellphone}}
                </td>
                
                <td>
                  {{customer.staff_email}}
                </td>
                <td>
                 
                  <p-badge [value]="customer.staff_total" pTooltip="Total" tooltipPosition="top"></p-badge>
                  <p-badge [value]="customer.staff_attended" pTooltip="Camps anteriores" tooltipPosition="top" severity="danger"
                    severity="danger"></p-badge>
                  <p-badge [value]="customer.staff_attend" pTooltip="Proximos camps" tooltipPosition="top"
                    severity="success" severity="success"></p-badge>


                </td>
                <td>
                  {{customer.staff_role}}
                </td>

                <td>
                  <button class="btn btn-primary" class="btn btn-primary"  (click)="verStaff(customer.staff_id)">ver staff</button>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">

            </ng-template>
          </p-table>

          </div>
          

        </div>
      </div>
      <div class="card border border-danger border-3" *ngIf="rol ==2 && (user_admin==true || user_coordinator==true)">
        <div class="bg-danger p-2"  (click)="headerStaffApun=!headerStaffApun" style="cursor: pointer;">
          <h1 class="card-title text-white">Staff apuntado</h1>

        </div>
        <div class="card-body" *ngIf="headerStaffApun">
          <div>
            <div class="col text-end">

            </div>
          </div>
          <div class="container-btn">
            <div>
              <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                <button type="button" class="btn btn-primary" id="dropdownBasic2"
                  ngbDropdownToggle>Reportes</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                  <button ngbDropdownItem>staff</button>
                
                </div>
              </div>
              <div ngbDropdown placement="top-end" class="d-inline-block"  style="margin-right: 10px;">
                <button type="button" class="btn btn-success" (click)="showMaximizableDialog()">no apuntado</button>
              
              </div>
              <div ngbDropdown placement="top-end" class="d-inline-block"  style="margin-right: 10px;">
                <button type="button" class="btn btn-danger" (click)="displayMaximizable2= !displayMaximizable2">seleciona staff</button>
                
              </div>
              
            </div>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>

          <div class="scroll-y">
            <p-table #dt [(selection)]="staffSelecionado" dataKey="staff_id"  [rowHover]="true" [rows]="10" [value]="staffApuntado"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="['name','country.name','representative.name','status']">

            <ng-template pTemplate="header">
              <tr>
                <th>
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th pSortableColumn="name" class="camp">
                  <div class="flex justify-content-between align-items-center" style="width: 50px;">
                    Foto
                  </div>
                </th>
                <th pSortableColumn="name" class="camp">
                  <div class="flex justify-content-between align-items-center" style="max-width: 200px; width: 200px;">
                    Nombre
                    <p-sortIcon field="name"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Fecha de nacimiento
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Telefono celulares
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 100px;">
                    Correos
                    <p-sortIcon field="precio"></p-sortIcon>
                  </div>
                </th>
               
                <th pSortableColumn="record.n" class="record">
                  <div class="flex justify-content-between align-items-center" style="min-width: 70px; width: 90px;">
                    Record
                    <p-sortIcon field="record.n"></p-sortIcon>
                  </div>
                </th>
              
                <th pSortableColumn="precio">
                  <div class="flex justify-content-between align-items-center" style="width: 80px;">
                  </div>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
              <tr class="p-selectable-row">
                <td>
                  <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                </td>
                <td>
                  <p-avatar [image]="'http://142.93.12.234:8000/'+customer.staff_photo" shape="circle" size="large"></p-avatar>
                </td>
                <td>
                  {{customer.staff_full_name}}
                </td>
               
                <td>
                  {{customer.staff_birthday}}
                  <br>

                </td>
                <td>
                 {{customer.staff_cellphone}}
                </td>
                
                <td>
                  {{customer.staff_email}}
                </td>
                <td>
                 
                  <p-badge [value]="customer.staff_total" pTooltip="Total" tooltipPosition="top"></p-badge>
                  <p-badge [value]="customer.staff_attended" pTooltip="Camps anteriores" tooltipPosition="top" severity="danger"
                    severity="danger"></p-badge>
                  <p-badge [value]="customer.staff_attend" pTooltip="Proximos camps" tooltipPosition="top"
                    severity="success" severity="success"></p-badge>


                </td>
               

                <td>
                  <button class="btn btn-primary" class="btn btn-primary" (click)="verStaff(customer.staff_id)">ver staff</button>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">

            </ng-template>
          </p-table>

          </div>
          

        </div>
      </div>



    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="showTable">
  <div>
    <form [formGroup]="formFood">


        <div class="row">
            <div class="col-lg-12">
                <div class="card p-3">
                    <h1>Editar Camp</h1>
                    <div class="card-body">
                        <div class="container-footer-parents">
                            <h4 class="ms-3">Datos Generales del Campamento</h4>
                        </div>
    
                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">Nombre del
                                    campamento*</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName ="name" #name (input)="validateName()">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">Sede*
                                </label>
                                <div class="control-label col-sm-9">
                                    <select style="width: 100%;" class="form-control" formControlName = "location_id" name="location_id" #location_id (change)="validateLocationId()">
                                        <option *ngFor="let item of location" [value]="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">Escuela*
                                    </label>
                                <div class="control-label col-sm-9">
                                    <select style="width: 100%;" class="form-control" formControlName ="school_id" name="school_id" #school_id (change)="validateSchoolId()">
                                        <option *ngFor="let item of school" [value]="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">Temporada*</label>
                                <div class="control-label col-sm-9">
                                    <select style="width: 100%;" class="form-control" formControlName ="season_id" name="season_id" #season_id (change)="validateSeasonId()">
                                        <option *ngFor="let item of temporada" [value]="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                              <label class="col-sm-3 col-form-label">Cuenta bancaria</label>
                              <div class="control-label col-sm-9">

                              <ng-select [items]="Catpaymanacout" [multiple]="true"   bindLabel="name" formControlName ="payment_accounts" 
                                ></ng-select>
                              </div>

                            </div>
                            <div class="row mb-4">
                              <label for="" class="col-sm-3 col-form-label">Recordatorio fecha limite de pago:</label>
                              <div class="col-sm-9">
                                  <input type="number" class="form-control" id="" formControlName="reminder_discount_days" #reminder_discount_days>
                              </div>
                          </div>
                       
                         

                           <div class="row mb-3">
                              <label class="col-sm-3 col-form-label">Tipo de pago</label>
                              <div class="control-label col-sm-9">

                              <ng-select [items]="tipoPago" bindValue="id" (change)="onChange($event)"   bindLabel="name"  [(ngModel)]="tipoDepago" [ngModelOptions]="{standalone: true}"
                                ></ng-select>
                              </div>
                           </div>
                          <div class="row mb-4">
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">Link para
                                    información adicional del acampador</label>
                                <div class="col-sm-9 mt-4">
                                    <input type="text" class="form-control" id="" formControlName="url" #url>
                                </div>
                            </div>
                            <div class="row mb-4">
                              <label for="" class="col-sm-3 col-form-label">Recordatorio fecha limite de pago:</label>
                              <div class="col-sm-9">
                                  <input type="number" class="form-control" id="" formControlName="reminder_discount_days" #reminder_discount_days>
                              </div>
                          </div>
    
    
                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label">Inicio del
                                    Campamento</label>
                                <div class=" control-label col-sm-9">
                                    <input type="datetime-local" class="form-control" placeholder="yyyy-mm-dd"  formControlName="start" #start (input)="validateStart()">
                                </div>
                            </div>
                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label">Final del
                                    Campamento</label>
                                <div class=" control-label col-sm-9">
                                    <input type="datetime-local" class="form-control" placeholder="yyyy-mm-dd" formControlName="end" #end (input)="validateEnd()">
                                </div>
                            </div>
                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label">Inicio de
                                    inscripción</label>
                                <div class=" control-label col-sm-9">
                                    <input type="datetime-local" class="form-control" placeholder="yyyy-mm-dd" formControlName="start_registration" #start_registration (input)="validateStartRegistration()">
                                </div>
                            </div>
                            <div class="row mb-4 mt-3">
                                <label for="" class="col-sm-3 col-form-label">Final de
                                    inscripción</label>
                                <div class=" control-label col-sm-9">
                                    <input type="datetime-local" class="form-control" placeholder="yyyy-mm-dd" formControlName="end_registration" #end_registration (input)="validateEndRegistration()">
                                </div>
                            </div>
    
                           
                            <div class="row">
                               
                                <div class="row">
                                    <div>
                                        <div class="col-3">
    
                                        </div>
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox"  value= "true"  formControlName="active"
                                            class="mb-2"/>
                                          <label style="margin-left: 20px; " for="sf">Activo</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-3">
    
                                        </div>
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox" value="true" formControlName="registration" 
                                            class="mb-2"/>
                                        <label style="margin-left: 20px;" for="ny">Registro abierto</label>
                                        </div>
                                    </div>
                                    <div>
                                      <div class="col-3">
  
                                      </div>
                                      <div class="p-field-checkbox col-9 d-flex flex-row">
                                          <input type="checkbox" value="true" formControlName="show_mercadopago_button" 
                                          class="mb-2"/>
                                      <label style="margin-left: 20px;" for="ny">Pagos atra vez de mercado pago</label>
                                      </div>
                                  </div>
                                    <div>
                                        <div class="col-3">
    
                                        </div>
                                          
                                    <div class="p-field-checkbox col-9 d-flex flex-row">
                                        <div class="p-field-checkbox">
                                            <input type="checkbox" value="true" formControlName="general_camp" 
                                                class="mb-2"/>
                                            <label style="margin-left: 20px;" for="ny">Campamento de verano</label>
                                        </div>
                                    </div>
    
                                    </div>
                                   
                                  
                                </div>
    
                            </div>
    
    
    
    
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card">
        
                        <div class="card-body">
                            <div class="container-footer-parents">
                                <h4 class="ms-3">Datos Importantes</h4>
                            </div>
        
                            <div class="mt-5">
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Monto del
                                        seguro*</label>
                                    <div class="col-sm-9">
                                        <input type="number" class="form-control" id="" formControlName = "insurance" #insurance>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Punto de
                                        reunion*</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="" formControlName="venue" #venue (input)="validateVenue()">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Fotos del
                                        camp*</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="" formControlName="photo_url" #photo_url (input)="validatePhotoUrl()">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Contraseña del
                                        camp*</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="" formControlName="photo_password" #photo_password (input)="validatePhotoPassword()">
                                    </div>
                                </div>
        
        
                            </div>
                        </div>
        
                    </div>
                    <div class="card">
        
                        <div class="card-body">
                            <div class="container-footer-parents">
                                <h4 class="ms-3">Precio y permisos </h4>
                            </div>
        
                            <div class="mt-5">
                             
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Precio del
                                        campamento*</label>
                                    <div class="col-sm-9">
                                        <input type="number" class="form-control" id="" formControlName="public_price" #public_price>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label for="" class="col-sm-3 col-form-label">Divisa*
                                        </label>
                                    <div class="control-label col-sm-9">
                                        <select style="width: 100%;" class="form-control" formControlName ="currency_id" name="currency_id" #currency_id>
                                            <option *ngFor="let item of currency" [value]="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                             
                               
                                <div class="row">
                                    <div class="row">
                                        <div class="col-3">
        
                                        </div>
                                    
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox" name="group1"  inputId="ny"
                                                class="mb-2" class="mb-2" value="true" formControlName = "show_paypal_button" #show_paypal_button>
                                            <label style="margin-left: 20px;" for="ny"> Mostrar boton de paypal</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
        
                                        </div>
                                    
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox" name="group1"  inputId="ny"
                                                class="mb-2" class="mb-2" value="true" formControlName = "show_payment_order" #show_payment_order>
                                            <label style="margin-left: 20px;" for="ny"> Habilitar orden de pago con referencia</label>
                                        </div>
                                    </div>
        
                                    <div class="row">
                                        <div class="col-3">
        
                                        </div>
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox"
                                                inputId="sf" class="mb-2" value="true" formControlName="show_payment_parent" #show_payment_parent>
                                            <label style="margin-left: 20px; " for="sf">Mostrar pagos a padres</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
        
                                        </div>
                                     
                                        <div class="p-field-checkbox col-9 d-flex flex-row">
                                            <input type="checkbox"
                                                inputId="xf" class="mb-2"  value="true" formControlName="show_rebate_parent"  #show_rebate_parent>
                                            <label style="margin-left: 20px; " for="xf"> Mostrar descuentos a padres</label>
                                        </div>
                                    </div>
                                  
                                   
                                    
                                </div>
        
                            </div>
                        </div>
        
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
        
                        <div class="container-footer-parents">
                            <h4 class="ms-3">Informacion extra </h4>
                        </div>
                        <div class="row">
        
                            <div class="col-lg-6 mt-4">
                                <div class="container">
                                    <div class="col mb-6">
                                        <h4 for="">Mensaje
                                            especial*</h4>
                                        <hr>
                                        <ckeditor class="mt-4" [editor]="Editor" type="text" formControlName="special_message"  >   </ckeditor>
                                      
                                    </div>
                                </div>
        
                            </div>
                            <div class="col-lg-6 mt-4">
                                <div class="container">
                                    <div class="col mb-6">
                                        <h4 for="" class="display-7">Mensaje especial
                                            para admin*</h4>
                                        <hr>
                                        <ckeditor class="mt-4" [editor]="Editor" type="text" formControlName="special_message_admin">   </ckeditor>
                                        
                                        
                                    </div>
                                </div>
        
                            </div>
                            <div class="col-lg-6 mt-4 ">
                                <div class="container">
                                    <div class="col mb-6">
                                        <h4 for="">
                                            Preguntas Extras</h4>
                                        <hr>
                                        <div *ngFor="let item of extra_question let i = index">
                                            <div class="footerMensje">
                                                <div>
                                                    <h3>Preguntas extras {{i+1}}</h3>
                                                </div>
                                                <div>
                                                    <h3 class="delet" (click)="deletExtraQuestion(i)">
                                                        Elminar pregunta
                                                    </h3>
                                                </div>
    
                                            </div>
                                            <div>                                           
                                                <div class="p-field-checkbox col-9 d-flex flex-row">
                                                    <input type="checkbox"  value= "true"  [(ngModel)]="item.is_required" name="is_required" [ngModelOptions]="{standalone: true}"
                                                    class="big-checkbox" />
                                                  <label style="margin-left: 10px; font-size: 15px; " >¿Obligatoria?</label>
                                                </div>
                                                
                                            </div>
                                           
                                            <input class="mt-4  form-control"  [(ngModel)]="item.question" name="question" [ngModelOptions]="{standalone: true}" />
             
    
                                        </div>
                                        <div class="alert alert-danger" role="alert" *ngIf="alerQuestion">
                                             Para poder agregar otra pregunta llena el campo antes
                                          </div>
                                            
                                        <button class="btn btn-primary col-12 mt-3" (click)="newExtraQuestion()">Agregar nueva pregunta</button>
        
                                    </div>
                                </div>
        
        
        
                            </div>
                            <div class="col-lg-6 mt-4">
                                <div class="row">
                                    <div >
                                        <h4 for="">
                                            Cargos extras</h4>
                                        <hr>
                                        <div *ngFor="let item of extra_charges let i = index">
                                            <div class="footerMensje">
                                                <div>
                                                    <h3>Cargo extras {{i+1}}</h3>
                                                </div>
                                                <div>
                                                    <h3 class="delet" (click)="deletExtracharges(i)">
                                                        Elminar cargo
                                                    </h3>
                                                </div>
    
                                            </div>
                                       
                                            <div>
                                                <label for="" class="col-sm-6 col-form-label">Nombre del
                                                    Cargo</label>
                                                <div class=" control-label col-lg-10">
                                                    <input type="text" class="form-control" [(ngModel)]="item.name" name="name" [ngModelOptions]="{standalone: true}">
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <label for="" class="col-3 col-form-label">Divisa*
                                                    </label>
                                                <div class="control-label col-10">
                                                    <select style="width: 100%;" class="form-control" [(ngModel)]="item.currency_id" name="currency_id" [ngModelOptions]="{standalone: true}">
                                                        <option *ngFor="let a of currency" [value]="a.id">{{a.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                          
                                            <div>
                                                <label for="" class="col-sm-6 col-form-label">Cantidad</label>
                                                <div class=" control-label col-lg-10">
                                                    <input type="number" class="form-control" [(ngModel)]="item.price" name="price" [ngModelOptions]="{standalone: true}">
                                                </div>
                                            </div>
                                       
                                       
                                       
                                        </div>  
                                    </div>
                                    <div class="alert alert-danger" role="alert" *ngIf="alercharges">
                                        Para poder agregar otra pregunta llena el campo antes
                                     </div>
                                       
                                   <button class="btn btn-primary col-12 mt-3" (click)="newExtracharges()">Agregar nuevo cargo</button>
    
                                </div>
        
        
        
                            </div>
                            
                            <hr class="mt-4">
                            <div class="col-lg-6 mt-4 mt-5">
                                <div class="row">
                                    <div >
                                        <h4 for="">
                                            Fechas sugeridas pagos</h4>
                                        <hr>
                                        <div *ngFor="let item of fecha_pago let i = index">
                                            <div class="mt-4">
                                                <div class="footerMensje">
                                                    <div>
                                                        <h3>Fechas {{i+1}}  <small (click)="deletFechaPago(i)" style="color: red;">X</small></h3>
                                                    </div>
                                                    
        
                                                </div>
                                           
                                                <div>
                                                    <label for="" class="col-sm-6 col-form-label">Fecha del pago</label>
                                                    <div class=" control-label col-lg-10">
                                                        <input type="date" class="form-control" [(ngModel)]="item.name" name="name" [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                           
                                              
                                                <div>
                                                    <label for="" class="col-sm-6 col-form-label">Cantidad</label>
                                                    <div class=" control-label col-lg-10">
                                                        <input type="number" class="form-control" [(ngModel)]="item.price" name="price" [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                       
                                       
                                       
                                        </div>  
                                    </div>
                                    <div class="alert alert-danger" role="alert" *ngIf="alercharges">
                                        Para poder agregar otra pregunta llena el campo antes
                                     </div>
                                       
                                   <button class="btn btn-primary col-12 mt-3" (click)="newFechaPago()">Agregar fecha de pago</button>
    
                                </div>
        
        
        
                          




</div>
        
        
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 ">
                <div class="card p-5">
                    <div class="btn-footer">
                     
                        <div class="mt-3">
                            <button class="btn btn-info  btn-lg" (click)="keepUpdate()" style="width: 90%;"
                                *ngIf="!spinner">Guardar</button>
                            <div class="spinner-border" role="status" *ngIf="spinner">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="mt-3">
                          <button class="btn btn-secondary  btn-lg"  style="width: 90%;"
                              *ngIf="!spinner" (click)="showTable=!showTable">Cancelar</button>
                        
                      </div>

                    </div>
                </div>
            </div>

        </div>
      </div>
    </form>
</div>
</div>
<div class="d-flex justify-content-center"  *ngIf="!cargando">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>



<p-dialog header="staff  no apuntado" [(visible)]="displayMaximizable" [modal]="true" [style]="{width: '90%'}" [maximizable]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="center">
      <div class="col-10">
        <div class="left mb-2">
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Global Search" />
        </div>
        <div class=" scroll-y">
        <p-table #dt [(selection)]="staffNoselecionado" dataKey="Staff.id"  [rowHover]="true" [rows]="10" [value]="listaStaff"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="['Staff.name','Staff.lastname_father','Staff.lastname_mother','status']">
    
        <ng-template pTemplate="header">
          <tr>
           
    
           <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
           </th>
            <th pSortableColumn="Staff.name" class="camp">
              <div class="flex justify-content-between align-items-center" style=" width: 60%;">
                Nombre
                <p-sortIcon field="Staff.name"></p-sortIcon>
              </div>
            </th>
            
    
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr class="p-selectable-row">
           <td>
            <p-tableCheckbox [value]="customer"></p-tableCheckbox>

           </td>
            <td>
              {{customer.Staff.name}} {{customer.Staff.lastname_father}}  {{customer.Staff.lastname_mother}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
    
        </ng-template>
      </p-table>
      </div>
      </div>
    </div>
  
    
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="displayMaximizable=false" label="Cancelar" class="p-button-text"></p-button>
            <p-button icon="pi pi-times"  label="Aceptar staff" (click)="agregarStaffNoApuntado()"></p-button>
        </ng-template>
</p-dialog>




<p-dialog header="Confirmar" [(visible)]="displayMaximizable2" [modal]="true" [style]="{width: '90%'}" [maximizable]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="center">
      <div class="col-10">
        <div class="left mb-2">
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Global Search" />
        </div>
        <div class=" scroll-y">
        <p-table #dt [(selection)]="staffSelecionado" dataKey="Staff.id"  [rowHover]="true" [rows]="10" [value]="staffSelecionado"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="['staff_full_name']">
    
        <ng-template pTemplate="header">
          <tr>
            
    
           
            <th pSortableColumn="Staff.name" class="camp">
              <div class="flex justify-content-between align-items-center" style=" width: 60%;">
                Nombre
                <p-sortIcon field="Staff.name"></p-sortIcon>
              </div>
            </th>
            
    
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr class="p-selectable-row">
            
            <td>
              {{customer.staff_full_name}} 
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
    
        </ng-template>
      </p-table>
      </div>
      </div>
    </div>
  
    
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="displayMaximizable2=false" label="Cancelar" class="p-button-text"></p-button>
            <p-button icon="pi pi-times"  label="Aceptar staff" (click)="agregarStaffApuntado()"></p-button>
        </ng-template>
</p-dialog>


<p-dialog header="Confirmar" [(visible)]="modalConfir" [modal]="true" [style]="{width: '90%'}" [maximizable]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="center">
      <div class="col-10">
        <select class="form-select" aria-label="Default select example"[(ngModel)]="selectRol">
          <option selected value="0">Seleciona un rol</option>
          <option *ngFor="let item of rolSatff" [value]="item.id">{{item.name}}</option>
         
        </select>
     
        <div class=" scroll-y">
        <p-table #dt [(selection)]="staffSelecionado" dataKey="Staff.id"  [rowHover]="true" [rows]="10" [value]="selectedCustomers"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="['staff_full_name']">
    
        <ng-template pTemplate="header">
          <tr>
           
    
           
            <th pSortableColumn="Staff.name" class="camp">
              <div class="flex justify-content-between align-items-center" style=" width: 60%;">
                Nombre
                <p-sortIcon field="Staff.name"></p-sortIcon>
              </div>
            </th>
            
    
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr class="p-selectable-row">
            
            <td>
              {{customer.staff_full_name}} 
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
    
        </ng-template>
      </p-table>
      </div>
      </div>
    </div>
  
    
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="modalConfir=false" label="Cancelar" class="p-button-text"></p-button>
            <p-button icon="pi pi-times"  label="Aceptar" (click)="selectstaff()" *ngIf="selectRol>0"></p-button>
        </ng-template>
</p-dialog>