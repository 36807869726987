<div class="container-fluid">
    <form [formGroup]="formParent"  (submit)="send()">


        <div class="row">
            <div class="col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <div class="container-footer-parents">
                            <img src="assets/images/camp/papa-mama.png" class="avatar-lg" alt="madre y padres">
                            <h4 class="ms-3 mt-3">{{textos[idoma]["titulo"]}}</h4>
                            <h5 class="mt-2">{{textos[idoma]["texto1"]}}
                            </h5>
                        </div>

                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for=""
                                    class="col-sm-3 col-form-label">{{textos[idoma]["texto2"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName="tutor_name"  (input)="gettutor_name()" #tutor_name>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label" >{{textos[idoma]["texto3"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName="tutor_lastname_father"  (input)="getTutor_lastname_father()" #tutor_lastname_father>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto4"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName="tutor_lastname_mother"  (input)="gettutor_lastname_mother()" #tutor_lastname_mother>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label" >{{textos[idoma]["texto5"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName = "tutor_cellphone"  (input)="getTutor_cellphone()" #tutor_cellphone>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto6"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName = "tutor_home_phone"  (input)="gettutor_home_phone()" #tutor_home_phone>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto7"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" formControlName = "tutor_work_phone"  (input)="gettutor_work_phone()" #tutor_work_phone>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-10">
                <div class="card">

                    <div class="card-body">
                        <div class="container-footer-parents">
                            <img src="assets/images/camp/papa-mama.png" class="avatar-lg" alt="madre y padres">
                            <h4 class="ms-3 mt-3">{{textos[idoma]["texto8"]}}</h4>
                        </div>

                        <h5 class="mt-4">{{textos[idoma]["texto9"]}}

                        </h5>




                        <div class="mt-5">
                            <div class="row mb-4">
                                <label for=""
                                    class="col-sm-3 col-form-label">{{textos[idoma]["texto2"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" (input)="getcontact_name()" id="" formControlName = "contact_name" #contact_name>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto3"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" (input)="getcontact_lastname_father()" formControlName = "contact_lastname_father" #contact_lastname_father>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto4"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" (input)="getcontact_lastname_mother()"  formControlName = "contact_lastname_mother" #contact_lastname_mother>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto5"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" (input)="getcontact_cellphone()" formControlName = "contact_cellphone" #contact_cellphone>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto7"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="" (input)="getcontact_work_phone()" formControlName = "contact_work_phone" #contact_work_phone>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto6"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" (input)="getcontact_home_phone()" id="" formControlName ="contact_home_phone"  #contact_home_phone>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto10"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" (input)="getcontact_email()" id="" formControlName = "contact_email" #contact_email>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label for="" class="col-sm-3 col-form-label">{{textos[idoma]["texto11"]}}</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" (input)="getconfirmEmail()" id="" formControlName = "confirmEmail"  #confirmEmail>
                                </div>
                            </div>
                            

                        </div>
                    </div>

                </div>
            </div>


            <div class="row">
                <div class=col-lg-12>
                    <div class="card p-5">
                        <div class="mb-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="terms" height="40px" formControlName = "terms"  >
                                <label class="form-check-label fs-3 ms-3" for="terms">
                                    {{textos[idoma]["texto12"]}}
                                </label>
                                <label class="form-check-label fs-3 ms-3" for="terms">
                                    <a
                                        href="https://demo.campercontrol.com/media/uploads/files/terminos-y-condiciones.pdf">Ver
                                        {{textos[idoma]["texto13"]}}</a>
                                </label>

                            </div>
                        </div>
                        <p class="fs-5">{{textos[idoma]["texto14"]}} <span> {{textos[idoma]["texto15"]}}</span>{{textos[idoma]["texto16"]}} </p>

                        <div class="btn-footer">
                            <button class="btn btn-secondary w-lg btn-lg">{{textos[idoma]["text018"]}}</button>
                            <button class="btn btn-info w-lg btn-lg"  *ngIf="!spinner">{{textos[idoma]["text017"]}}</button>
                            <div class="spinner-border" role="status" *ngIf="spinner">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- end row -->
</div>