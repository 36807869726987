


<div>

    <div class="card border border-info border-1">
        <div class="bg-info p-2">
            <h1 class="card-title text-white">Lista de capacitaciones</h1>
        </div>
        <div class="card-body">
        <div class="container-btn mt-1 mb-3">
            <div>
                <div ngbDropdown placement="top-end" class="d-inline-block" style="margin-right: 10px;">
                    <button type="button" class="btn btn-primary" (click)="showDialog()">Agregar nueva capacitacion</button>

                </div>
            </div>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
        </div>

        <p-table #dt [value]="listcatalogos" [rows]="100"
            [globalFilterFields]="['comment','id','camper_id','updated_at']"
            [(selection)]="selectCatalogos" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true">

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">id<p-sortIcon field="id"
                        style="font-size: 10px;"></p-sortIcon></th>
                    <th pSortableColumn="comment">Comentario<p-sortIcon field="comment"
                            style="font-size: 10px;"></p-sortIcon></th>
                    <th pSortableColumn="camper_id">Mostrar a <p-sortIcon field="camper_id"></p-sortIcon></th>
                    <th pSortableColumn="updated_at">Fecha <p-sortIcon field="updated_at"></p-sortIcon></th>
                    <th pSortableColumn="user_id">Usuario <p-sortIcon field="user_id"></p-sortIcon></th>

                    <th style="width: 40px;"> </th>
                    <th style="width: 40px;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td>
                        {{customer.id}}
                    </td>
                    <td>
                        {{customer.comment}}
                    </td>
                    <td>
                        {{customer.camper_id}}
                    </td>
                    <td>
                        {{customer.updated_at}}
                    </td>
                    <td>
                        {{customer.user_id}}
                    </td>
                    <td>
                        <button class="btn btn-warning" (click)="update(customer)">Editar</button>

                    </td>
                    <td>
                        <button class="btn btn-danger" (click)="deletModal(customer.name,customer.id)">Eliminar</button>
                    </td>
                 
                </tr>
            </ng-template>

        </p-table>
    </div>
    </div>





</div>

<p-dialog header="Title" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Agregar nueva comentario
       </h2> 
    </ng-template>
    <div class="col">
        <form action="" [formGroup]="formFood">
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Usuario</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="user_id">
                       <option *ngFor="let item of listUser" [value]="item.id">{{item.email}}</option>
                    
                      </select>  
                </div>
                  
            </div>
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Camper</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="camper_id">
                       <option *ngFor="let item of listCampers" [value]="item.id">{{item.name}}</option>
                    
                      </select>  
                </div>
                  
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Comentario</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="comment">
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Mostrar a</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="show_to">
                        <option value="0">Sin Grupo</option>
                        <option value="1">Tutor</option>
                        <option value="2">Staff</option>
                        <option value="3">Escuela</option>
                        <option value="3">Cordinador</option>
                        <option value="3">Admi</option>
                        <option value="3">Teacher</option>
                        <option value="3">Doctor</option>
                      </select>  
                </div>               
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="true" id="defaultCheck1" formControlName="is_public">
                <label class="form-check-label" for="defaultCheck1">
                 Es publico
                </label>
              </div>
            
        </form>
        <div class="alert alert-primary" role="alert" *ngIf="statuAgrgado">
            Se Agrego correctamente
          </div>        
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="guardar()" [disabled]="formFood.invalid">Guardar</button>
    </ng-template>
</p-dialog>
<p-dialog header="Title" [(visible)]="display2" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Editar  restricion de alimimentos
       </h2> 
    </ng-template>
    <div class="col">
        <form action="" [formGroup]="formFood">
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Usuario</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="user_id">
                       <option *ngFor="let item of listUser" [value]="item.id">{{item.email}}</option>
                    
                      </select>  
                </div>
                  
            </div>
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Camper</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="camper_id">
                       <option *ngFor="let item of listCampers" [value]="item.id">{{item.name}}</option>
                    
                      </select>  
                </div>
                  
            </div>
            <div class="row mb-4">
                <label for="" class="col-sm-3 col-form-label">Comentario</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" id="" formControlName="comment">
                </div>         
            </div>
            <div class="row mb-4">
                <label for="" class="col-3 col-form-label">Mostrar a</label>
                <div class="col-9">
                    <select class="form-select" aria-label="Default select example" formControlName="show_to">
                        <option value="0">Sin Grupo</option>
                        <option value="1">Tutor</option>
                        <option value="2">Staff</option>
                        <option value="3">Escuela</option>
                        <option value="3">Cordinador</option>
                        <option value="3">Admi</option>
                        <option value="3">Teacher</option>
                        <option value="3">Doctor</option>
                      </select>  
                </div>               
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="true" id="defaultCheck1" formControlName="is_public">
                <label class="form-check-label" for="defaultCheck1">
                 Es publico
                </label>
              </div>
            
        </form>
        <div class="alert alert-primary" role="alert" *ngIf="statuAgrgado">
            Se actualizo correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal2()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()">Guardar</button>
    </ng-template>
</p-dialog>

<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
       <h2>
        Eliminar  restricion de alimimentos
       </h2> 
    </ng-template>
    <div class="col">
       {{TextElimint}}
        <div class="alert alert-danger" role="alert" *ngIf="statuAgrgado">
            Se elimino correctamente
          </div>

          
    </div>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="keepUpdate()">Eliminar</button>
    </ng-template>
</p-dialog>

