<div class="container-fluid">
    <div class="row d-lg-flex justify-content-lg-center">
        <div class="col-lg-10 col-xxl-4">

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-start align-items-center ">
                            <img src="https://demo.campercontrol.com/media/images/forms/maestra.png" alt="imagen perfil" class="me-3 img-perfil">
                            <h1>Información de la escuela </h1>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="campo my-3">
                                <label for="" class="form-label">Nombre de la escuela</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Página web</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Dirección</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    </div>
    <div class="row d-lg-flex justify-content-lg-center">
        <div class="col-lg-8 col-xxl-4">

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-start align-items-center ">
                            <img src="	https://demo.campercontrol.com/media/images/forms/secretaria.png" alt="imagen perfil" class="me-3 img-perfil">
                            <h1>Primer Contacto</h1>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="campo my-3">
                                <label for="" class="form-label">Nombre</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Teléfono fijo</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Celular</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Correo electrónico</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    </div>
    <div class="row d-lg-flex justify-content-lg-center">
        <div class="col-lg-8 col-xxl-4">

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-start align-items-center ">
                            <img src="	https://demo.campercontrol.com/media/images/forms/secretaria.png" alt="imagen perfil" class="me-3 img-perfil">
                            <h1>Segundo Contacto</h1>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="campo my-3">
                                <label for="" class="form-label">Nombre</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Teléfono fijo</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Celular</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Correo electrónico</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    </div>
    <div class="row d-lg-flex justify-content-lg-center">
        <div class="col-lg-8 col-xxl-4">

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-start align-items-center ">
                            <img src="https://demo.campercontrol.com/media/images/forms/secretaria.png" alt="imagen perfil" class="me-3 img-perfil">
                            <h1>Tercer Contacto</h1>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="campo my-3">
                                <label for="" class="form-label">Nombre</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Teléfono fijo</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Celular</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="campo my-3">
                                <label for="" class="form-label">Correo electrónico</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    </div>
    <div class="row d-lg-flex justify-content-lg-center">
        <div class="col-lg-8 col-xxl-4">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <p>Los correos de contacto registrados recibirán los mismos mensajes que se les envía a los padres de familia.</p>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-secondary">Cancelar</button>
                                <button class="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</div>
