<div class="container-fluid">
    <div class="card">
        <div class="card border border-info border-3">
            <div class="bg-info p-2">
                <h1 class="card-title text-white">Tiendita</h1>
            </div>
            <div class="card">
                <p-tabView class="p-2">
                    <p-tabPanel header="Reporte de gastos">
                        <div class="row">
                            <div class="col-10">
                                <ng-select [(ngModel)]="id">
                                    <ng-option *ngFor=" let camp of alumnos " [value]="camp.id"> {{camp.id}} |
                                        {{camp.acampador}} </ng-option>
                                </ng-select>

                            </div>
                            <div class="col-2">
                                <button class="btn btn-primary" (click)=" buscarCamp()"  (click)="isCollapsed = false">Buscar</button>
                            </div>

                        </div>
                        <hr>
                        <div class="card border border-success border-3">
                            <div class="bg-success p-2" (click)="isCollapsed = ! isCollapsed">
                                <h1 class="card-title text-white">Detalle de movimientos
                                </h1>
                            </div>
                            <div class="p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                <div class="container-btn">
                                    <div class="center-search">
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Global Search" class="mb-2" />
                                    </div>
        
                                </div>
                                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                                [value]="tabla" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                                [loading]="loading" [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [filterDelay]="0" [globalFilterFields]="['fecha','descripcion','entrada','sale','saldo',]">
    
                                <ng-template pTemplate="header">
                                    <tr>
    
                                        <th pSortableColumn="fecha" class="camp">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 120px;">
                                                fecha
                                                <p-sortIcon field="fecha"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="descripcion">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 230px;">
                                                Descripción
                                                <p-sortIcon field="descripcion"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="entrada">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Entrada
                                                <p-sortIcon field="entrada"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="sale">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Sale
                                                <p-sortIcon field="sale"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="saldo">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Saldo
                                                <p-sortIcon field="saldo"></p-sortIcon>
                                            </div>
                                        </th>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-tabla>
                                    <tr class="p-selectable-row">
    
                                        <td>
                                            {{tabla.fecha}}
                                        </td>
                                        <td>
                                            {{tabla.descripcion}}
                                        </td>
                                        <td>
                                            {{tabla.entrada}}
                                        </td>
                                        <td>
                                            {{tabla.sale}}
                                        </td>
                                        <td>
                                            {{tabla.saldo}}
                                        </td>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" class="mt-2">
    
                                </ng-template>
                            </p-table>

                            </div>
                            
                           

                        </div>
                      
                       
                    </p-tabPanel>
                    <p-tabPanel header="Abonar saldo">
                        <div class="card border border-warning border-3">
                            <div class="bg-warning p-2" >
                                <h1 class="card-title text-white">Abonar saldo
                                </h1>
                            </div>
                            <div class="row p-4">
                                <div class="col-5">
                                    <ng-select [(ngModel)]="id" placeholder="id camper">
                                        <ng-option *ngFor=" let camp of alumnos " [value]="camp.id"> {{camp.id}} |
                                            {{camp.acampador}} </ng-option>
                                    </ng-select>
    
                                </div>
                                <div class="col-5">
                                    <input type="number" name="" id=""  class="form-control" placeholder="monto">
    
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary" (click)=" buscarCamp()"  (click)="isCollapsed = false">Abonar saldo</button>
                                </div>

                            </div>
                          
                            <div class="p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                <div class="container-btn">
                                    <div class="alin-search">
                                        <div>
                                            <h2>Detalles de Movimientos</h2>
                                        </div>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Global Search" class="mb-2" />
                                           
                                    </div>
        
                                </div>
                                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                                [value]="tabla" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                                [loading]="loading" [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [filterDelay]="0" [globalFilterFields]="['fecha','descripcion','entrada','sale','saldo',]">
    
                                <ng-template pTemplate="header">
                                    <tr>
    
                                        <th pSortableColumn="fecha" class="camp">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 120px;">
                                                fecha
                                                <p-sortIcon field="fecha"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="descripcion">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 230px;">
                                                Descripción
                                                <p-sortIcon field="descripcion"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="entrada">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Entrada
                                                <p-sortIcon field="entrada"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="sale">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Sale
                                                <p-sortIcon field="sale"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="saldo">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Saldo
                                                <p-sortIcon field="saldo"></p-sortIcon>
                                            </div>
                                        </th>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-tabla>
                                    <tr class="p-selectable-row">
    
                                        <td>
                                            {{tabla.fecha}}
                                        </td>
                                        <td>
                                            {{tabla.descripcion}}
                                        </td>
                                        <td>
                                            {{tabla.entrada}}
                                        </td>
                                        <td>
                                            {{tabla.sale}}
                                        </td>
                                        <td>
                                            {{tabla.saldo}}
                                        </td>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" class="mt-2">
    
                                </ng-template>
                            </p-table>

                            </div>
                            
                           

                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Compra en tiendita ">
                        <div class="card border border-warning border-3">
                            <div class="bg-warning p-2" >
                                <h1 class="card-title text-white">Compra en tiendita
                                </h1>
                            </div>
                            <div class="row p-4">
                                <div class="col-5">
                                    <ng-select [(ngModel)]="id" placeholder="id camper">
                                        <ng-option *ngFor=" let camp of alumnos " [value]="camp.id"> {{camp.id}} |
                                            {{camp.acampador}} </ng-option>
                                    </ng-select>
    
                                </div>
                                <div class="col-5">
                                    <input type="number" name="" id=""  class="form-control" placeholder="monto">
    
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary" (click)=" buscarCamp()"  (click)="isCollapsed = false">Comprar</button>
                                </div>

                            </div>
                          
                            <div class="p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                <div class="container-btn">
                                    <div class="alin-search">
                                        <div>
                                            <h2>Detalles de Movimientos</h2>
                                        </div>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Global Search" class="mb-2" />
                                           
                                    </div>
        
                                </div>
                                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                                [value]="tabla" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                                [loading]="loading" [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [filterDelay]="0" [globalFilterFields]="['fecha','descripcion','entrada','sale','saldo',]">
    
                                <ng-template pTemplate="header">
                                    <tr>
    
                                        <th pSortableColumn="fecha" class="camp">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 120px;">
                                                fecha
                                                <p-sortIcon field="fecha"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="descripcion">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 230px;">
                                                Descripción
                                                <p-sortIcon field="descripcion"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="entrada">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Entrada
                                                <p-sortIcon field="entrada"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="sale">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Sale
                                                <p-sortIcon field="sale"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="saldo">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Saldo
                                                <p-sortIcon field="saldo"></p-sortIcon>
                                            </div>
                                        </th>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-tabla>
                                    <tr class="p-selectable-row">
    
                                        <td>
                                            {{tabla.fecha}}
                                        </td>
                                        <td>
                                            {{tabla.descripcion}}
                                        </td>
                                        <td>
                                            {{tabla.entrada}}
                                        </td>
                                        <td>
                                            {{tabla.sale}}
                                        </td>
                                        <td>
                                            {{tabla.saldo}}
                                        </td>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" class="mt-2">
    
                                </ng-template>
                            </p-table>

                            </div>
                            
                           

                        </div>
                    </p-tabPanel>
                    
                    <p-tabPanel header="Rembolso en Efectivo">
                        <div class="card border border-warning border-3">
                            <div class="bg-warning p-2" >
                                <h1 class="card-title text-white">Rembolso en efectivo
                                </h1>
                            </div>
                            <div class="row p-4">
                                <div class="col-5">
                                    <ng-select [(ngModel)]="id" placeholder="id camper">
                                        <ng-option *ngFor=" let camp of alumnos " [value]="camp.id"> {{camp.id}} |
                                            {{camp.acampador}} </ng-option>
                                    </ng-select>
    
                                </div>
                                <div class="col-5">
                                    <input type="number" name="" id=""  class="form-control" placeholder="monto">
    
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary" (click)=" buscarCamp()"  (click)="isCollapsed = false">Rembolso en efectivo</button>
                                </div>

                            </div>
                          
                            <div class="p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                <div class="container-btn">
                                    <div class="alin-search">
                                        <div>
                                            <h2>Detalles de Movimientos</h2>
                                        </div>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Global Search" class="mb-2" />
                                           
                                    </div>
        
                                </div>
                                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                                [value]="tabla" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                                [loading]="loading" [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [filterDelay]="0" [globalFilterFields]="['fecha','descripcion','entrada','sale','saldo',]">
    
                                <ng-template pTemplate="header">
                                    <tr>
    
                                        <th pSortableColumn="fecha" class="camp">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 120px;">
                                                fecha
                                                <p-sortIcon field="fecha"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="descripcion">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 230px;">
                                                Descripción
                                                <p-sortIcon field="descripcion"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="entrada">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Entrada
                                                <p-sortIcon field="entrada"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="sale">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Sale
                                                <p-sortIcon field="sale"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="saldo">
                                            <div class="flex justify-content-between align-items-center"
                                                style="width: 90px;">
                                                Saldo
                                                <p-sortIcon field="saldo"></p-sortIcon>
                                            </div>
                                        </th>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-tabla>
                                    <tr class="p-selectable-row">
    
                                        <td>
                                            {{tabla.fecha}}
                                        </td>
                                        <td>
                                            {{tabla.descripcion}}
                                        </td>
                                        <td>
                                            {{tabla.entrada}}
                                        </td>
                                        <td>
                                            {{tabla.sale}}
                                        </td>
                                        <td>
                                            {{tabla.saldo}}
                                        </td>
    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" class="mt-2">
    
                                </ng-template>
                            </p-table>

                            </div>
                            
                           

                        </div>
                      
                    </p-tabPanel>
                </p-tabView>
            </div>












        </div>

    </div>