<header class="container-fluid" *ngIf="inscripcion">

    <div class="row">
        <div class="card col-lg-12 shadow-lg  mb-5 bg-body  rounded-top">
            <div class="campamentos-header" >
                <div class="bg-primary text-white ps-5 pe-5 pt-3 pb-3" style="width: 100%;">

                    <h3 class="text-white">{{camper_band.full_name}}</h3>
                    <h5 class="text-white">{{camper_band.school}}</h5>
                    <h6 class="text-white">{{camper_band.birthday}}</h6>
                </div>
                <section class="campamentos pt-4">
                    <div class="Campementos_anteriores">
                        <div>
                            {{camper_band.past_camps}}
                        </div>
                        <div>
                            <p>{{textos[idoma]["titulo"]}}  </p>
                        </div>
                    </div>
                    <div class="foto-perfil">
                        <img src="http://142.93.12.234:8000/{{camper_band.photo}}" class="avatar-lg rounded-circle" alt="">
                        <button class="btn-rounded btn btn-success mt-3 ">
                            <a class="text-white" style="width: 100%;" [routerLink]="['../camper/'+id]" >{{textos[idoma]["titulo15"]}}  </a>
                        </button>
                    </div>


                    <div class="Campamentos_proximo">
                        <div>
                          {{camper_band.future_camps}}
                        </div>
                        <div>
                            <p>{{textos[idoma]["titulo1"]}}  </p>
                        </div>

                    </div>


                </section>
            </div>

        </div>
    </div>

    <div class="alert alert-primary" role="alert" *ngIf="idoma =='esp'">
      <h4 class="alert-heading">Configuración de Inscripciones</h4>
      <p>Si desea hacer inscripciones múltiples, cambie el estado del siguiente interruptor:</p>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="inscripcionesSwitch" value="true" [(ngModel)]="multipleInscr">
        <label class="custom-control-label" for="inscripcionesSwitch">Habilitar Inscripciones Múltiples</label>
      </div>
      <hr>
      <p class="mb-0">Haga clic en el interruptor para cambiar la configuración.</p>
    </div>


    <div class="alert alert-primary" role="alert" *ngIf="idoma =='eng'">
      <h4 class="alert-heading">Registration Settings</h4>
      <p>If you wish to enable multiple registrations, change the state of the following switch:</p>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="registrationsSwitch"  value="true" [(ngModel)]="multipleInscr">
        <label class="custom-control-label" for="registrationsSwitch">Enable Multiple Registrations</label>
      </div>
      <hr>
      <p class="mb-0">Click the switch to change the setting.</p>
    </div>

    <!--campamentos a los que asisitire-->
    <div >

        <div class="card border border-info border-1" style="display: none;">
              <div class="bg-info p-2">
                <h1 class="card-title text-white">{{textos[idoma]["titulo3"]}}  </h1>
      
              </div>
              <div class=" scroll-y">

              <div class="card-body">
                <div>
                  <div class="col text-end">
      
                  </div>
                </div>
                <div class="container-btn">
                    <div class="d-inline-block" style="margin-right: 10px;">
                        <div> 
                            <button class="btn btn-success" (click)="filterCamps()" >Inscribir  a camp(s)</button>
                      </div>
                    </div>
                 
                 
                </div>
      
                <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="5" [value]="customer" 
                  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                  [globalFilterFields]="['name','location_id','start','end','public_price']"
                  [paginator]="true">
      
                  <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                          </th>
      
                      <th pSortableColumn="name" class="camp">
                        <div class="flex justify-content-between align-items-center" style="width: 250px;">
                          Camp
                          <p-sortIcon field="name"></p-sortIcon>
                        </div>
                      </th>
                      <th pSortableColumn="location_id" class="camp">
                        <div class="flex justify-content-between align-items-center" style="max-width: 200px; width: 200px;">
                          {{textos[idoma]["titulo4"]}}  
                          <p-sortIcon field="location_id"></p-sortIcon>
                        </div>
                      </th>
                      <th pSortableColumn="start" class="record">
                        <div class="flex justify-content-between align-items-center" style="min-width: 70px; width: 140px;">
                          {{textos[idoma]["titulo5"]}}  
                          <p-sortIcon field="start"></p-sortIcon>
                        </div>
                      </th>
                      <th pSortableColumn="end">
                        <div class="flex justify-content-between align-items-center" style="width: 140px;">
                          {{textos[idoma]["titulo6"]}}  
                          <p-sortIcon field="end"></p-sortIcon>
                        </div>
                      </th>
                   
                      <th pSortableColumn="public_price" style="width: 130px; min-width: 120px;">
                        <div class="flex justify-content-between align-items-center">
                          {{textos[idoma]["titulo7"]}}  
                          <p-sortIcon field="public_price"></p-sortIcon>
                        </div>
                      </th>
      
                     
                      <th pSortableColumn="precio">
                        <div class="flex justify-content-between align-items-center" style="width: 140px;">
                        </div>
                      </th>
      
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-customer>
                    <tr class="p-selectable-row">
                        <td>
                            <p-tableCheckbox [value]="customer" ></p-tableCheckbox>
                          </td>
                     
                      <td >
                        {{customer.camp_name}}
                      </td>
                      <td> 
                        {{customer.location_name}}
                      </td>
                      <td>
      
                       {{customer.camp_start}}
      
      
                      </td>
                      <td>
      
                       
                       {{customer.camp_end}}
      
                      </td>
                      <td>
                       {{customer.public_price}}
      
                      </td>
                   
      
                      <td>
                        <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../camp-info/'+id+'/'+customer.camp_id]">{{textos[idoma]["titulo8"]}}  </button>

                      </td>
      
      
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
      
                  </ng-template>
                </p-table>
    
            </div>
            </div>
          </div>




        <div class="card border border-success border-1" *ngIf="subscribe_camps.length>0">
            
            <div class="bg-success p-2">
                <h1 class="card-title text-white">{{textos[idoma]["titulo11"]}}  </h1>
      
              </div>
              <br>
              <p-table #dt [value]="subscribe_camps" [rows]="5" 
                  [globalFilterFields]="['camp_name','location_name','camp_start','camp_end','public_price']"
                  [(selection)]="selectedProducts" [rowHover]="true" dataKey="camp_id"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true"
                  responsiveLayout="scroll"
                  [paginator]="true"
                scrollHeight="flex">
                
                  <ng-template pTemplate="header">
                      <tr>
                          
                          <th pSortableColumn="camp_name">Camp <p-sortIcon field="camp_name" style="font-size: 10px;"></p-sortIcon></th>
                          <th pSortableColumn="location_name">{{textos[idoma]["titulo4"]}}  <p-sortIcon field="location_name"></p-sortIcon></th>
                          <th pSortableColumn=" camp_end">{{textos[idoma]["titulo5"]}}  <p-sortIcon field="camp_end"></p-sortIcon></th>
                          <th pSortableColumn="camp_start">{{textos[idoma]["titulo6"]}}  <p-sortIcon field="camp_start"></p-sortIcon></th>
                          <th pSortableColumn="public_price">{{textos[idoma]["titulo7"]}}  <p-sortIcon field="public_price"></p-sortIcon></th>
                          <th pSortableColumn="camper_payment_balance">{{textos[idoma]["titulo12"]}}  <p-sortIcon field="camper_payment_balance"></p-sortIcon></th>

                          <th></th>
      
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-customer>
                      <tr>
                          <td (click)="routerCamps(customer.camp_id)" style="cursor: pointer;">
                              {{customer.camp_name}}
                            </td>
                            <td> 
                              {{customer.location_name}}
                            </td>
                            <td>
            
                             {{customer.camp_end}}
            
            
                            </td>
                            <td>
            
                             
                             {{customer.camp_start}}
            
                            </td>
                            <td>
                             {{customer.public_price}}
            
                            </td>
                          
                             <td>
                              {{customer.camper_payment_balance}}
             
                             </td>
                         
            
                            <td>
                              <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../camp-info/'+id+'/'+customer.camp_id]">ver campamento</button>
                            </td>
      
                      </tr>
                  </ng-template>
                  
              </p-table>
        </div>

        <div class="card border border-secondary border-1"  *ngIf="available_school_camps.length>0">
            
            <div class="bg-secondary p-2">
                <h1 class="card-title text-white">{{textos[idoma]["titulo9"]}}  </h1>
      
              </div>
              
              <br>
                           
              <div class="card-body">
              <div>
                <div class="col text-end">
    
                </div>
              </div>
              <div class="container-btn">
                  <div class="d-inline-block" style="margin-right: 10px;">
                      <div> 
                          <button class="btn btn-success" (click)="filterCampsScholl()" *ngIf="multipleInscr">Inscribir  a camp(s)</button>
                    </div>
                  </div>
               
               
              </div>
              <p-table #dt [value]="available_school_camps" [rows]="5" 
                  [globalFilterFields]="['camp_name','location_name','camp_start','camp_end','public_price']"
                  [(selection)]="campsSchool" [rowHover]="true" dataKey="camp_id"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  [showCurrentPageReport]="true"
                  responsiveLayout="scroll"
                  [paginator]="true"
                scrollHeight="flex">
                
                  <ng-template pTemplate="header">
                      <tr>
                        <th *ngIf="multipleInscr">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                          <th pSortableColumn="camp_name">Camp <p-sortIcon field="camp_name" style="font-size: 10px;"></p-sortIcon></th>
                          <th pSortableColumn="location_name">{{textos[idoma]["titulo4"]}}   <p-sortIcon field="location_name"></p-sortIcon></th>
                          <th pSortableColumn="camp_start">{{textos[idoma]["titulo5"]}}   <p-sortIcon field="camp_start"></p-sortIcon></th>
                          <th pSortableColumn="camp_end">{{textos[idoma]["titulo6"]}}   <p-sortIcon field="camp_end"></p-sortIcon></th>
                          <th pSortableColumn="public_price">{{textos[idoma]["titulo7"]}}   <p-sortIcon field="precio"></p-sortIcon></th>
      
                          <th></th>
      
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-customer>
                      <tr>
                        <td *ngIf="multipleInscr">
                          <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                        </td>
                          <td (click)="routerCamps(customer.camp_id)" style="cursor: pointer;">
                              {{customer.camp_name}}
                            </td>
                            <td> 
                              {{customer.location_name}}
                            </td>
                            <td>
            
                             {{customer.camp_end}}
            
            
                            </td>
                            <td>
            
                             
                             {{customer.camp_start}}
            
                            </td>
                            <td>
                             {{customer.public_price}}
            
                            </td>
                         
            
                            <td>
                              <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../camp-info/'+id+'/'+customer.camp_id]" *ngIf="multipleInscr">ver campamento</button>
                          
                              <button class="btn btn-primary" class="btn btn-success" (click)="inscribirCampsUnico(customer.camp_id)" *ngIf="!multipleInscr">Inscribir a camp</button>
                            </td>
      
                      </tr>
                  </ng-template>
                  
              </p-table>
            </div>
        </div>
        <div class="card border border-info border-1" *ngIf="summer_school_camps.length>0">
            
          <div class="bg-info p-2">
              <h1 class="card-title text-white">{{textos[idoma]["titulo10"]}}  </h1>

            </div>
            <div class="card-body">
              <div>
                <div class="col text-end">
    
                </div>
              </div>
              <div class="container-btn">
                  <div class="d-inline-block" style="margin-right: 10px;">
                      <div> 
                          <button class="btn btn-success" (click)="filterCampsSummer()" *ngIf="multipleInscr">Inscribir  a camp(s)</button>
                    </div>
                  </div>
               
               
              </div>
            
            <br>
            <p-table #dt [value]="summer_school_camps" [rows]="5"  [(selection)]="campsSummer" 
                [globalFilterFields]="['camp_name','location_name','camp_start','camp_end','public_price']"
                dataKey="camp_id"
                responsiveLayout="scroll"
                scrollHeight="flex"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true"
                [paginator]="true"
                >
              
                <ng-template pTemplate="header">
                    <tr>
                      <th *ngIf="multipleInscr">
                        <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
                      </th>
                        <th pSortableColumn="camp_name">Camp <p-sortIcon field="camp" style="font-size: 10px;"></p-sortIcon></th>
                        <th pSortableColumn="location_name">{{textos[idoma]["titulo4"]}}  <p-sortIcon field="sede"></p-sortIcon></th>
                        <th pSortableColumn=" camp_end">{{textos[idoma]["titulo5"]}}  <p-sortIcon field="inicio"></p-sortIcon></th>
                        <th pSortableColumn="camp_start">{{textos[idoma]["titulo6"]}}  <p-sortIcon field="termino"></p-sortIcon></th>
                        <th pSortableColumn="public_price">{{textos[idoma]["titulo7"]}}  <p-sortIcon field="precio"></p-sortIcon></th>
    
                        <th></th>
    
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                      <td *ngIf="multipleInscr">
                        <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                      </td>
                        <td  (click)="routerCamps(customer.camp_id)" style="cursor: pointer;">
                            {{customer.camp_name}}
                          </td>
                          <td> 
                            {{customer.location_name}}
                          </td>
                          <td>
          
                           {{customer.camp_end}}
          
          
                          </td>
                          <td>
          
                           
                           {{customer.camp_start}}
          
                          </td>
                          <td>
                           {{customer.public_price}}
          
                          </td>

                            <td>
                              <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../camp-info/'+id+'/'+customer.camp_id]" *ngIf="multipleInscr">ver campamento</button>
                              <button class="btn btn-primary" class="btn btn-success" (click)="inscribirCampsUnico(customer.camp_id)" *ngIf="!multipleInscr">Inscribir a camp</button>

                            </td>
                         
                       
          
    
                    </tr>
                </ng-template>
                
            </p-table>
            </div>
      </div>
      <div class="card border border-secondary border-1"  *ngIf="passed_camps.length>0">
            
        <div class="bg-secondary p-2">
            <h1 class="card-title text-white">{{textos[idoma]["titulo13"]}} </h1>
  
          </div>
          <br>
          <p-table #dt [value]="passed_camps" [rows]="5" 
              [globalFilterFields]="['camp_name','location_name','camp_start','camp_end','public_price']"
              [(selection)]="selectedProducts" [rowHover]="true" dataKey="camp_id"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true"
              responsiveLayout="scroll"
                scrollHeight="flex"
                [paginator]="true">
            
              <ng-template pTemplate="header">
                  <tr>
                      
                      <th pSortableColumn="camp_name">Camp <p-sortIcon field="camp" style="font-size: 10px;"></p-sortIcon></th>
                      <th pSortableColumn="location_name">{{textos[idoma]["titulo4"]}}  <p-sortIcon field="sede"></p-sortIcon></th>
                      <th pSortableColumn=" camp_end">{{textos[idoma]["titulo5"]}}  <p-sortIcon field="inicio"></p-sortIcon></th>
                      <th pSortableColumn="camp_start">{{textos[idoma]["titulo6"]}}  <p-sortIcon field="termino"></p-sortIcon></th>
                      <th pSortableColumn="public_price">{{textos[idoma]["titulo7"]}}  <p-sortIcon field="precio"></p-sortIcon></th>
  
                      <th></th>
  
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-customer>
                  <tr>
                      <td  (click)="routerCamps(customer.camp_id)" style="cursor: pointer;">
                          {{customer.camp_name}}
                        </td>
                        <td> 
                          {{customer.location_name}}
                        </td>
                        <td>
        
                         {{customer.camp_end}}
        
        
                        </td>
                        <td>
        
                         
                         {{customer.camp_start}}
        
                        </td>
                        <td>
                         {{customer.public_price}}
        
                        </td>
                     
        
                        <td>
                          <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../camp-info/'+id+'/'+customer.camp_id]">{{textos[idoma]["titulo8"]}} </button>
                        </td>
  
                  </tr>
              </ng-template>
              
          </p-table>
    </div>
    <div class="card border border-danger border-1" *ngIf="cancelled_camps.length>0">
        
        <div class="bg-danger p-2">
            <h1 class="card-title text-white">{{textos[idoma]["titulo14"]}}</h1>
  
          </div>
          <br>

          <p-table #dt [value]="cancelled_camps" [rows]="5" 
              [globalFilterFields]="['camp_name','location_name','camp_start','camp_end','public_price']"
              [(selection)]="selectedProducts" [rowHover]="true" dataKey="camp_id"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true"
              responsiveLayout="scroll"
                scrollHeight="flex"
                [paginator]="true">
            
              <ng-template pTemplate="header">
                  <tr>
                      
                      <th pSortableColumn="camp_name">Camp <p-sortIcon field="camp" style="font-size: 10px;"></p-sortIcon></th>
                      <th pSortableColumn="location_name">{{textos[idoma]["titulo4"]}} <p-sortIcon field="sede"></p-sortIcon></th>
                      <th pSortableColumn=" camp_end">{{textos[idoma]["titulo5"]}} <p-sortIcon field="inicio"></p-sortIcon></th>
                      <th pSortableColumn="camp_start">{{textos[idoma]["titulo6"]}} <p-sortIcon field="termino"></p-sortIcon></th>
                      <th pSortableColumn="public_price">{{textos[idoma]["titulo7"]}} <p-sortIcon field="precio"></p-sortIcon></th>
  
                      <th></th>
  
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-customer>
                  <tr>
                      <td (click)="routerCamps(customer.camp_id)" style="cursor: pointer;">
                          {{customer.camp_name}}
                        </td>
                        <td> 
                          {{customer.location_name}}
                        </td>
                        <td>
        
                         {{customer.camp_end}}
        
        
                        </td>
                        <td>
        
                         
                         {{customer.camp_start}}
        
                        </td>
                        <td>
                         {{customer.public_price}}
        
                        </td>
                     
        
                        <td>
                          <button class="btn btn-primary" class="btn btn-primary" [routerLink]="['../../../parents/camp-info/'+id+'/'+customer.camp_id]">{{textos[idoma]["titulo8"]}}</button>
                        </td>
  
                  </tr>
              </ng-template>
              
          </p-table>
    </div>
       

    </div>
    
    <!--campamentos disponible Escolares -->
   
     <!--campamentos disponible inscribir -->
    

    <!--campamentos a los que asisti -->
   

    <!--campamentos cancelados -->
   




    
    

</header>
<ng-template #centerDataModal let-modal>
  <div class="modal-header">
    
  </div>
  <div class="modal-body">
      <div class="text-center mb-4">
          <div class="avatar-md mx-auto mb-4">
              <div class="avatar-title bg-light rounded-circle text-success h1">
                  <i class="mdi mdi-account-multiple-check"></i>
              </div>
          </div>

          <div class="row justify-content-center">
              <div class="col-xl-10">
                  <h4 class="text-success">Su Inscripcion fue realizada !</h4>
                  <p class="text-muted font-size-14 mb-4">Permitanos unos segundos y lo redigiremos al siguiente paso YA CASI TERMINAMOS
                  </p>

                 

              </div>
          </div>
      </div>
  </div>
</ng-template>

<div  class="container-fluid" *ngIf="!inscripcion && cargosExtr">
  <div class="card col-12 border border-info border-1">
    <div class="bg-info p-2">
      <h1 class="card-title text-white">Preguntas y cargos extras </h1>
    </div>
    <div class="mt-3" style="margin: 0 auto;">
      <div class="col-11 container">
        <div class="alert alert-warning col-12" role="alert">
          <strong>Hola,</strong> No te preocupes, estamos a punto de terminar tu inscripción. Solo necesitamos que respondas algunas preguntas y confirmes algunos cargos adicionales. ¡Gracias por tu paciencia!
      </div>
      </div>
     
     
    <div class="container col-11  mt-5">
      <div class="">
        <h3 class="text-center ">Preguntas extras</h3>
          <div class="form-group" *ngFor="let pregunta of extra_questions; let i = index">
            <label for="pregunta{{ i }}"> {{ pregunta.question }} <span style="color:rgb(52, 52, 54); font-weight: 800;"> ( {{ pregunta.camp_name }})</span> :</label>
            <input type="text" class="form-control" id="pregunta{{ i }}" [(ngModel)]="extra_questions[i].answer" name="answer">
          </div>
      </div>
  
    </div>
    
   

    <div class="container mt-5 col-11" *ngIf="extra_charges.length>0">
      <h3 class="text-center">Cargos extras</h3>
        <div class="list-group" *ngFor="let extra_charge of extra_charges; let i = index">
          <label class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            <div>
              <input
                type="checkbox"
                class="form-check-input me-3"
                id="cargo{{ i }}"
                name="camp_extra_charge_is_selected"
                [(ngModel)]="extra_charges[i].camp_extra_charge_is_selected"
              >
              {{ extra_charge.camp_extra_charge_name }} <span style="color:rgb(52, 52, 54); font-weight: 800;"> ({{ extra_charge.camp_name }})</span>
            </div>
            <span >{{ extra_charge.camp_extra_charge_price | currency }}</span>
          </label>
        </div>
    </div>
  
    
    
    
    <div class="container col-10 mb-2">
      <button style="width: 100%;" class="btn btn-primary " (click)="enviarCargosPreguntas()">Enviar</button>

    </div>
    </div>
  </div>

</div>

<app-update-perfil  *ngIf="!inscripcion && !cargosExtr"  [typeSucribe]="typeSucribe" (inscribirCamps)="suscribeCamps($event)">

</app-update-perfil>