<div class="container-d" style="display: flex; justify-content: center; align-items: center;">
    <div class="col-xl-4 col-sm-12 col-md-7 mx-auto" style="background-color: white;">
       
       
            <div class=" p-4">
                <div class="w-100">

                    <div class=" h-100">
                        <div class="">
                            <a routerLink="/" class="d-block auth-logo">
                                <img src="../../assets/images/camp/logoCamper.png" alt="" height="100px"
                                    class="auth-logo-dark" style="margin: 0 auto;">
                                <img src="../../assets/assets/images/camp/logoCamper.png" alt="" height="100px"
                                    class="auth-logo-light">
                            </a>
                        </div>
                        <div>
                            <h2 class="text-primary">Cambiar contraseña</h2>

                        </div>
                        <div class="mt-3">
                            <div class="mb-4" role="alert">
                                Por favor ingresa su nueva contraseña
                            </div>

                            <div class="alert alert-primary mt-2" role="alert" *ngIf="alertPass">
                                La contraseña se ha cambiado correctamente, lo proxima vez que inicie sesion podra usar su nueva contraseña                             </div>
                            <form [formGroup]="resetPass"  class="mt-5 mb-5">

                                <div>
                                    <label for="email">Nueva Contraseña :  </label>
                                    <input type="email" class="form-control" id="email" formControlName="password"
                                        placeholder="">
                                        <div  class="alert alert-warning mt-1">Se usará para ingresar a Camper Control. Mínimo de 6 caracteres , 1 letra mayuscula, 1 letra minuscula y un caracter especial </div>


                                </div>
                               

                                <div class="mt-3 d-grid" *ngIf="!spinner">
                                    <button class="btn btn-primary btn-block" type="button"
                                        (click)="resetPasword()">Cambiar contraseña</button>
                                </div>

                                <div class="spinner-border" role="status" *ngIf="spinner">
                                 
                                  </div>

                            </form>

                            
                            <div class="mt-5">
                                <div class=" d-grid"> <button class="btn btn-danger" (click)="routerInfo()">Iniciar
                                    Sesión</button> </div>

                            </div>

                           



                        </div>
                    </div>
                </div>

            </div>
    </div>
</div>