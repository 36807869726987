<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card border border-success border-3">
                <div class=" scroll-y">
                    <div class="bg-success p-2">
                        <h1 class="card-title text-white">Capacitación</h1>

                    </div>
                    <div class="card-body">
                        <div>
                            <div class="col text-end">

                            </div>
                        </div>
                        <div class="container-btn">
                       
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Global Search" />
                        </div>

                        <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                            [value]="customer" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                            [loading]="loading" [paginator]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0"
                            [globalFilterFields]="['name','country.name','representative.name','status']">

                            <ng-template pTemplate="header">
                                <tr>

                                   
                                    <th pSortableColumn="name" class="camp">
                                        <div class="flex justify-content-between align-items-center"
                                            style="max-width: 200px; width: 140px;">
                                            Capacitación
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="record.n" class="record">
                                        <div class="flex justify-content-between align-items-center"
                                            style="min-width: 70px; width: 100px;">
                                            Sede
                                            <p-sortIcon field="record.n"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="precio">
                                        <div class="flex justify-content-between align-items-center"
                                            style="width: 120px;">
                                            Inicia
                                            <p-sortIcon field="precio"></p-sortIcon>
                                        </div>
                                    </th>

                                    <th pSortableColumn="sede" style="width: 130px; min-width: 120px;">
                                        <div class="flex justify-content-between align-items-center">
                                            Termina
                                            <p-sortIcon field="sede"></p-sortIcon>
                                        </div>
                                    </th>

                                    <th>

                                    </th>

                                   
                                   

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-customer>
                                <tr class="p-selectable-row">

                                  
                                    <td>
                                        {{customer.name}}
                                    </td>
                                    <td>
                                        La oficina
                                    </td>
                                    <td>
                                        2022-01-28
                                    </td>
                                    <td>
                                        2022-01-29
                                    </td>
                                   

                                    <td>
                                        <button class="btn btn-primary" class="btn btn-primary">ver capacitación</button>
                                    </td>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">

                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>