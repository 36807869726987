<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="card border border-info border-3">
        <div class="bg-info p-2">
          <h4 class="card-title text-white">Acampadores</h4>

        </div>
        <div class="p-3">



          <div class="container-btn mt-1 mb-3" >
              <div class="m-2">
                <button class="btn btn-success " (click)="openModal(content)"><i
                  class="bx bxs-plus-circle me-1"></i>Nueva Agrupación
              </button>
              </div>
              <div class="m-2">
                <button class="btn btn-success " (click)="display4 =true"><i
                  class="bx bxs-plus-circle me-1"></i>Editar Agrupación
              </button>
              </div>

              <div class="m-2">
                <button class="btn btn-success " (click)="display3 = !display3"><i
                  class="bx bxs-plus-circle me-1"></i>ASIGNAR ACAMPADORES A AGRUPACIÓN

              </button>
              </div>
              
               
          
          </div>

          <p-table [value]="listCampers">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="birthday">Birthday <p-sortIcon field="birthday"></p-sortIcon></th>
                <th pSortableColumn="gender">Gender <p-sortIcon field="gender"></p-sortIcon></th>
                <th pSortableColumn="grade">Grade <p-sortIcon field="grade"></p-sortIcon></th>
                <!-- Generar columnas de agrupaciones dinámicamente con sortField personalizado -->
                <th *ngFor="let grouping of groupingsList" [pSortableColumn]="grouping">
                  {{ grouping }} <p-sortIcon [field]="grouping"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.birthday }}</td>
                <td>{{ item.gender }}</td>
                <td>{{ item.grade }}</td>
                <!-- Mostrar si el elemento pertenece a la agrupación usando hasGrouping -->
                <td *ngFor="let grouping of groupingsList">
                  <span *ngIf="hasGrouping(item, grouping)">✔</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
          
          
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Agregar agrupación</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div >
      <div class="row">
        <div class="col-12">

          <div class="mb-3">
            <label class="control-label">Agrupación</label>
            <ng-select [items]="grupos"
            bindLabel="nameComplet" 
            bindValue="id"
            [(ngModel)]="selectGrupos">
            </ng-select>
          </div>

         
          <div class="mb-3">
            <label class="control-label" for="capacidad">Capacidad Máxima</label>
            <input class="form-control" placeholder=0 type="number" name="capacidad" id="capacidad"
            [(ngModel)]="capMax" >

          </div>


        </div>
      </div>
      <button type="submit" class="btn btn-success" id="btn-save-event" (click)="createGroup()">
        Guardar
      </button>
    </div>
  </div>
</ng-template>


<p-dialog header="Title" [(visible)]="display3" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '98vw'}"  [modal]="true">
  <ng-template pTemplate="header">
     <h2>
      Asignar agrupación  
     </h2> 
  </ng-template>
  <div class="col">
    <div class="col-10 mx-auto">
      <form action="">
        <div class="row mb-4">
          <label for="" class="col-sm-12 col-form-label">Agrupación</label>
          <div class="col-sm-12">
              <ng-select   [items]="listGruposImscritos"
              bindLabel="nameCample" 
              bindValue="id"
              (change)="changeGrups($event)" >
                 
              </ng-select>
          </div>         
      </div>
      
     
      </form>

      <p-table #dt [value]="listcatalogos"[globalFilterFields]="['name']" [rows]="100"
      dataKey="id" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [showCurrentPageReport]="true"  [(selection)]="selectCatalogos"  [rowsPerPageOptions]="[10,25,100]" [paginator]="true" >

      <ng-template pTemplate="header">
        <tr>
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
           </th>
          <th pSortableColumn="name">Acampador<p-sortIcon field="name" style="font-size: 10px;"></p-sortIcon></th>
          
          <th pSortableColumn="birthday">Fecha de Nacimiento<p-sortIcon field="birthday"
            style="font-size: 10px;"></p-sortIcon></th>
        <th pSortableColumn="gender">Sexo<p-sortIcon field="gender"
            style="font-size: 10px;"></p-sortIcon></th>
        <th pSortableColumn="grade">Grado escolar<p-sortIcon field="grade"
            style="font-size: 10px;"></p-sortIcon></th>
       

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr>
          <td>
            <p-tableCheckbox [value]="customer"></p-tableCheckbox>

           </td>
          <td>
            {{customer.name}}
          </td>
          
          <td>
            {{customer.birthday}}
          </td>
        


          <td>
            {{customer.gender}}

          </td>
          <td>
            {{customer.grade}}

          </td>

        </tr>
      </ng-template>

    </p-table>

    </div>
   

        
  </div>
  <ng-template pTemplate="footer">
      <button type="button" class="btn btn-outline-secondary" (click)="this.display3 = false">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveGrouping()" *ngIf="!spinner">Guardar</button>
      <div class="spinner-border text-primary" role="status" *ngIf="spinner" >
      </div>
  </ng-template>
</p-dialog>

<p-dialog header="Editar Capacidad de agrupaciòn" [(visible)]="display4" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '98vw'}"  [modal]="true">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Editar capacidad</h5>
  </div>
  <div class="modal-body">
    <div >
      <div class="row">
        <div class="col-12">

          <div class="col-sm-12">
            <ng-select   [items]="listGruposImscritos"
            bindLabel="nameCample" 
            bindValue="id"
            (change)="EditchangeGrups($event)" >
               
            </ng-select>
        </div>   

         
          <div class="mb-3">
            <label class="control-label" for="capacidad">Capacidad Máxima</label>
            <input class="form-control" placeholder=0 type="number" name="capacidad" id="capacidad"
            [(ngModel)]="capEdit" >

          </div>

          
        </div>
      
      </div>
     
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-outline-secondary" (click)="this.display4 = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveEdite()" *ngIf="!spinner">Guardar</button>
    <div class="spinner-border text-primary" role="status" *ngIf="spinner" >
    </div>
</ng-template>
</p-dialog>
