<div class="container-fluid">
  <app-page-title title="Advanced Table" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Advanced Data Table</h4>
          <p class="card-sub-title">
            Example of datatable.
          </p>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize" class="form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                  class="d-inline-flex align-items-center">Search:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <th></th>
                  <th sortable="name" (sort)="onSort($event)">Name</th>
                  <th sortable="position" (sort)="onSort($event)">Position</th>
                  <th sortable="office" (sort)="onSort($event)">Office</th>
                  <th sortable="age" (sort)="onSort($event)">Age</th>
                  <th sortable="date" (sort)="onSort($event)">Start date</th>
                  <th sortable="salary" (sort)="onSort($event)">Salary</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async;let i=index">
                <tr>
                  <td align="center">
                    <a class="expand-row" (click)="changeValue(i)">
                      +
                    </a>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.position" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.office" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.age" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.date" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="table.salary" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <div ngbDropdown placement="bottom-left">
                      <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                        aria-expanded="true"></i>
                      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript: void(0);"><i class="mdi mdi-plus"></i>Add</a>
                        <a class="dropdown-item" href="javascript: void(0);"><i
                            class="mdi mdi-account-edit"></i>Edit</a>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead class="bg-dark text-white">
                        <tr>
                          <th>CountryName</th>
                          <th>Price/Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let table of tables$ | async; let i = index">
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Showing
                {{service.startIndex}} to
                {{service.endIndex}} of {{service.totalRecords}}
                entries
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-end pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Editable Table</h4>
          <p class="card-sub-title">
            Example of editable table.
          </p>
            <ng2-smart-table [settings]="settings" [source]="editableTable"></ng2-smart-table>
        </div>
    </div>
    </div>
  </div>
</div>
<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Modal Heading</h5>
    <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
  </div>
  <div class="modal-body">
    <h5>Overflowing text to show scroll behavior</h5>
    <p>Cras mattis consectetur purus sit amet fermentum.
      Cras justo odio, dapibus ac facilisis in,
      egestas eget quam. Morbi leo risus, porta ac
      consectetur ac, vestibulum at eros.</p>
    <p>Praesent commodo cursus magna, vel scelerisque
      nisl consectetur et. Vivamus sagittis lacus vel
      augue laoreet rutrum faucibus dolor auctor.</p>
    <p>Aenean lacinia bibendum nulla sed consectetur.
      Praesent commodo cursus magna, vel scelerisque
      nisl consectetur et. Donec sed odio dui. Donec
      ullamcorper nulla non metus auctor
      fringilla.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    <button type="button" class="btn btn-primary">Save changes</button>
  </div>
</ng-template>
