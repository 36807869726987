import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-card-capacitaciones',
  templateUrl: './card-capacitaciones.component.html',
  styleUrls: ['./card-capacitaciones.component.scss']
})
export class CardCapacitacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
