const userGridData = [
    {
        id: 1,
        name: 'David McHenry',
        colegio: 'Colegio Camper Control',
        saldo: '1,000',
        projects: ['Campamento con preguntas'],
        email: 'david@skote.com'
    },
    {
        id: 2,
        image: 'assets/images/users/avatar-2.jpg',
        name: 'Frank Kirk',
        colegio: 'Colegio Camper Control',
        saldo: '0',
        projects: ['Campamento con comentario de admi', 'Campamento con preguntas', 'evento con preguntas'],
        email: 'frank@skote.com'
    },
    {
        id: 3,
        image: 'assets/images/users/avatar-3.jpg',
        name: 'Rafael Morales',
        colegio: 'Escuela Camper Control',
        saldo: '5,000',
        projects: ['Campamento con comentario de admi:500', 'Campamento con preguntas:500'],
        email: 'Rafael@skote.com'
    },
    {
        id: 4,
        name: 'Mark Ellison',
        colegio: 'Escuela Camper Control',
        saldo: '5,000',

        projects: ['Campamento con preguntas'],
        email: 'mark@skote.com'
    },
    {
        id: 5,
        image: 'assets/images/users/avatar-4.jpg',
        name: 'Minnie Walter',
        colegio: 'Escuela Camper Control',
        saldo: '5,000',

        projects: ['Campamento con comentario de admi'],
        email: 'minnie@skote.com'
    },
    {
        id: 6,
        image: 'assets/images/users/avatar-5.jpg',
        name: 'Shirley Smith',
        colegio: 'Escuela Camper Control',
        saldo: '5,000',

        projects: ['Campamento con comentario de admi'],
        email: 'shirley@skote.com'
    },
    {
        id: 7,
        name: 'John Santiago',
        colegio: 'Colegio Camper Control',
        saldo: '5,500',

        projects: ['Campamento con comentario de admi'],
        email: 'john@skote.com'
    },
    {
        id: 8,
        image: 'assets/images/users/avatar-5.jpg',
        name: 'Colin Melton',
        colegio: 'Colegio Camper Control',
        saldo: '0',

        projects: ['Campamento con comentario de admi'],
        email: 'colin@skote.com'
    },
];

export { userGridData };
