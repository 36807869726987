import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trofeos',
  templateUrl: './trofeos.component.html',
  styleUrls: ['./trofeos.component.scss']
})
export class TrofeosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
