import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {

  private baseUrl = 'http://142.93.12.234:8000/';  // Cambia esto por la URL de tu backend

  constructor(private http: HttpClient) {}

  // Método para crear una preferencia en el backend
  createPreference(camp_id,camper_id,pago): Observable<any> {
    return this.http.get(`${this.baseUrl}mercado_pago/create_payment_link/${camp_id}/${camper_id}/${pago}`);
  }
}
