<header class="container-fluid">

    <div class="row">
        <div class="col-lg-12">

            <div class="card campamentos-header">
                <div class="bg-primary text-white  p-3">

                    <h3 class="text-white"> {{infoCamp.name}}   {{infoCamp.lastname_father}} {{infoCamp.lastname_mother}} </h3>
                    <h6 class="text-white">{{infoCamp.birthdayA}}</h6>
                </div>
                <section class="campamentos ">
                    <div class="Campementos_anteriores">
                        <div>
                            {{this.bandHitoryasitio}}
                        </div>
                        <div>
                            <p>Camps Anteriores</p>
                        </div>
                    </div>
                    <div class="foto-perfil">
                        <img src="http://142.93.12.234:8000/{{infoCamp.photo}}" class="avatar-lg rounded-circle" alt="">
                      
                    </div>


                    <div class="Campamentos_proximo">
                        <div>
                            {{this.bandHitoryasistir}}
                        </div>
                        <div>
                            <p>Camps Proximo</p>
                        </div>

                    </div>


                </section>
            </div>
        </div>
    </div>
</header>
<main class="container-fluid">
    <div class="row">
        <div class="col-lg-7">
            <div class="card p-5">
                <div class="header">
                    <div>
                        <h4>Perfil del Staff </h4>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-sm"  (click)="update()">editar</button>
                    </div>
                </div>
                
            
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <div class="container-fluid">
                            <div class="titulo-info">
                                <i class="bx bx-id-card"></i>
                                <h4 style="margin-left: 10px;">Staff</h4>
                                

                            </div>

                            <div class="container-fluid">
                                
                                <p> <span class="fw-bold">Bio:</span>  {{infoCamp.bio}}</p>

                                <p> <span class="fw-bold">Nombre:</span>  {{infoCamp.name}}   {{infoCamp.lastname_father}} {{infoCamp.lastname_mother}} </p>
                                <p> <span class="fw-bold">Fecha de nacimiento:</span>{{infoCamp.birthday}}</p>
                                <p> <span class="fw-bold">Edad: </span> {{infoCamp.birthdayA}}</p>
                                <p> <span class="fw-bold">RFC:</span> {{infoCamp.rfc}}</p>
                                <p> <span class="fw-bold">CURP: </span> {{infoCamp.curp}}</p>
                             <!-- <p> <span class="fw-bold"> Correo: </span>{{infoCamp.email}} // faltante del back</p>
 -->   
                            </div>
                            <hr>
                            <div class="row">
                                <div class="container-fluid mt-5">
                                    <div class="titulo-info col-12">
                                        <i class="bx bxs-bookmark-star"></i>
                                        <h4 style="margin-left: 10px;"> Datos de contacto</h4>
                                    </div>
        
                                    <div class="container-fluid">
        
                                     <!--  <p> <i class="bx bxs-envelope"></i>  {{infoCamp.email}} // falta del back</p>-->  
                                        <p> <i class="bx bxl-whatsapp"></i>  {{infoCamp.staff_contact_cellphone}}</p>
                                        <p> <i class="bx bxs-contact"></i>  {{infoCamp.staff_contact_homephone}}</p>
                                        <p> <i class="bx bxl-facebook-square"></i>  {{infoCamp.facebook}}</p>   
                                    </div>
        
                                </div>
                            </div>
                           
                            <hr>

                        </div>
                    
                       
                        <div class="container-fluid">
                            <div class="titulo-info">
                                <i class="fas fa-ambulance "></i>
                                <h4 style="margin-left: 10px;">Info Médica</h4>
                            </div>

                            <div class="container-fluid">
                                <p> <span class="fw-bold">Tipo de sangre: </span> {{infoCamp.blood_type}}</p>
                                <p> <span class="fw-bold">Vacunas:</span>   <span class="badge bg-info ms-1"  *ngFor="let item of vacunasACtivos">{{item.name}}</span></p>
                              

                                <p> <span class="fw-bold"> Enfermedades:</span>{{infoCamp.affliction}}</p>
                                <p> <span class="fw-bold"> Alergias a Medicamentos:</span>{{infoCamp.drug_allergies}}</p>
                                <p> <span class="fw-bold">Otras Alergias:</span>{{infoCamp.other_allergies}}</p>
                                <p> <span class="fw-bold">Alteraciones nocturnas:</span>{{infoCamp.other_allergies}}</p>
                                <p> <span class="fw-bold">Fobias o miedos:</span>{{infoCamp.nocturnal_disorders}}</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <hr>
                    <div class="col-lg-12">
                       
                        <div class="container-fluid mt-5">
                            <div class="titulo-info">
                                <i class="fas fa-drumstick-bite"></i>
                                <h4 style="margin-left: 10px;">Alimentación</h4>
                            </div>

                            <div class="container-fluid">
                                <p> <span class="fw-bold">Comida prohibida:</span>{{infoCamp.prohibited_foods}}</p>
                                <p> <span class="fw-bold">Alimentacion:</span>   <span class="badge bg-info ms-1"  *ngFor="let item of catalogosComida">{{item.name}}</span></p>
                            </div>

                        </div>
                        <hr>
                        
                        <div class="container-fluid mt-5">
                            <div class="titulo-info">
                                <i class="mdi-card-account-phone-outline"></i>
                                <h4 style="margin-left: 10px;">Contacto de emergencia</h4>
                            </div>

                            <div class="container-fluid" style="margin-left: 20px;">
                                {{infoCamp.staff_contact_name}}
                                <p> <i class="bx bxl-whatsapp"></i>  {{infoCamp.staff_contact_cellphone}}</p>
                                <p> <i class="bx bxs-city"></i>  {{infoCamp.staff_contact_homephone}}</p>


                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>


        <div class="col-lg-5">
            <div class="col-lg-12">
                <div class="top-p">

                </div>
                <div class="card p-4">
                    <div class="chat">
                        <div class="row">
                            <h5 class="col-9">Algo más que debamos saber sobre </h5>
                            <p-badge [value]="comenarios.length" class="col-1"></p-badge>
                            <div class="col-1" style="font-size: 25px; margin-top: -5px;" (click)="isCollapsed = ! isCollapsed">+</div>
                        </div>
                    <div  #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <hr >
                        <!-- en este espacio es donde va el contenedor del chat-->
                        <div class="chat-espacio">
                            <div class="p-1">
                                <div class="mt-2"*ngFor="let item of comenarios">
                                    <div >
                                        <div class="row">
                                            <div class="col-2 mt-4">
                                                <img src="assets/images/camp/chat-padres.png" class="rounded-circle "width="100%"/>
                                            </div>
                                            <div class="col-10" >
                                                <p class="fecha-chat" style="text-align: right;"> {{item.StaffComment.created_at.substring(0,10)}}</p>
                                                <div class="col-12 mensaje">
                                                    <p class="p-2">{{item.StaffComment.comment}}
                                                    </p>
                                            </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                   
                                    
                                </div>
                              
                            </div>
                            
                           

                        </div>
                        <div class="col">
                            <label for="">Mostrar este comentario a</label>
                            <select name="" id="" [(ngModel)]="typecoment">
                                <option value=3>Admin</option>
                                <option value=2>Coordinador</option>
                                <option value=1>staff</option>


                            </select>

                        </div>
                        <div class="col">
                            <input type="text" name="" id="" class="form-control" placeholder="Username"
                                aria-label="Username" [(ngModel)]="comment">
                                <div class="d-flex flex-row-reverse">
                                    <button class="btn btn-primary mt-3" (click)="comentario()">Guardar Comentario</button>
                                </div>
                           
                        </div>
                        
                    </div>
                       
                    </div>

                </div>

            </div>
            <div class="col-lg-12">
                <div class="card pt-2 pb-2">
                    <p-timeline [value]="historialCaps" align="left" styleClass="customized-timeline" class="margin-timile">
                        <ng-template pTemplate="marker" let-event>
                            <div class="timilin-logo" [ngClass]="{'cancell':event.type=='cancelled','suscribe':event.type=='subscribe','passed':event.type=='passed'}">
                                <i *ngIf="event.type =='subscribe'" class="fas fa-check"></i>
                                <i *ngIf="event.type =='cancelled'" class="far fa-times-circle"></i>
                                <i *ngIf="event.type =='passed'" class="fas fa-check"></i>

                            </div>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <div class="contendor-timiline mt-3 p-1" [ngClass]="{'passed':event.type=='passed','suscribe':event.type=='subscribe'}">
                                <h3 style="cursor: pointer;"> {{event.camp_name}} <i class="fa fa-info-circle" (click)="link(event.camp_id)"></i></h3>
                                
                            </div>
                          
                            <div class="contenedor-p" *ngIf="event.type!='passed' && event.type !='cancelled'">
                                <p-tag value="Confirmado" severity="success"  [rounded]="true" *ngIf=" event.confirmed_staff"></p-tag>
                                <p-tag value="Sin confirmar" severity="warning"  [rounded]="true" *ngIf=" !event.confirmed_staff"></p-tag>
                                <p class="icononos-s mt-3">{{event.camp_start.substring(0,10)}} - {{event.camp_end.substring(0,10)}}<i class="fas fa-calendar-alt ms-3"> </i></p>
                                
                            </div>
                        </ng-template>
                    </p-timeline>
                </div>

            </div>

        </div>


    </div>




</main>