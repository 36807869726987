


<div id="layout-wrapper">
    <app-vertical></app-vertical>
    <div class="main-content">
      <div class="page-content">

        <div class="container-fluid p-3 mn">
            <form [formGroup]="formParent"  >
        
        
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <img src="assets/images/camp/papa-mama.png" class="avatar-lg" alt="madre y padres">
                                    <h4 class="ms-3 mt-3">{{cabezeras[idoma]["titulo"]}}</h4>
                                    <h5 class="mt-2">{{cabezeras[idoma]["texto1"]}}
                                    </h5>
                                </div>
        
                                <div class="mt-5 container-fluid ">
                                    <div class="row mb-3">
                                        <label for="" class="col-sm-12 col-form-label" >{{cabezeras[idoma]["texto2"]}}</label>
                                        <div class="col-sm-12">
                                            
                                            <input type="text" class="form-control" id="" formControlName="email" name="email"   (input)="getemail()" #email (focus)="alertcorreo =true" (focusout)="alertcorreo = false">
                                            <p> <small style=" font-size: 14px; color: red;"> {{cabezeras[idoma]["texto6"]}}</small></p>
                                            <div *ngIf="alertcorreo" class="alert alert-warning mt-2" role="alert">
                                                Asegurate de que sea  una dirección de correo  válida, ya que te enviaremos mensajes referentes a los camps a los que asistiran tus hijo(a)s
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label" >{{cabezeras[idoma]["texto3"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName="confirmEmail"  name="confirmEmail" #emailConfir  (input)="getconfirmEmail()" (focus)="alertConfirCorre =true" (focusout)="alertConfirCorre = false">
                                            <div  class="alert alert-danger" *ngIf="confirmEmailAlert"><small style="color: red; font-size: 14px    ;" >El correo no coincide.</small></div>
                                            <div *ngIf="alertConfirCorre" class="alert alert-warning mt-2" role="alert">
                                                Al finalizar el registro se enviará un mensaje de confirmación a esta cuenta de correo, te pedimos que revises tu bandeja de Correo no deseado para asegurar  que lo recibiste bien.Si no lo recibes ,ponte en contacto con nosotros
                                              </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="" class="col-sm-12 col-form-label">{{cabezeras[idoma]["texto4"]}}</label>
                                       
                                        <div class="col-sm-12">
                                            <div class="input-group auth-pass-inputgroup">
                                                <input [type]="passwordType" class="form-control" formControlName="password" name="password"
                                                    placeholder="Enter password" aria-label="Password"
                                                    aria-describedby="password-addon"
                                                    #password (input)="getpassword()">
    
                                                <button class="btn btn-light " type="button" id="password-addon"><i
                                                        class="mdi mdi-eye-outline" (click)="cambioTipo()"></i></button>
    
                                            </div>
                                            <div  class="alert alert-warning mt-1">{{cabezeras[idoma]["texto8"]}}</div>

                                        </div>
                                        
                                        <div  class="alert alert-danger" *ngIf="confirmPaswordAlert"><small style="color: red; font-size: 14px    ;">Las contraseñas no coinciden.</small></div>

                                    </div>
                                   
                                    <div class="row mb-4">

                                        <label for="userpassword">{{cabezeras[idoma]["texto5"]}}</label>
                                        <div>
                                            <div class="input-group auth-pass-inputgroup">
                                                <input [type]="passwordType" class="form-control" formControlName="confirmPassword" name="confirmPassword"
                                                    placeholder="Enter password" aria-label="Password"
                                                    aria-describedby="password-addon"
                                                    #confirmPassword (input)="getconfirmPassword()">
    
                                                <button class="btn btn-light " type="button" id="password-addon"><i
                                                        class="mdi mdi-eye-outline" (click)="cambioTipo()"></i></button>
    
                                            </div>
                                            <div  class="alert alert-danger" *ngIf="confirmPaswordAlert"><small style="color: red; font-size: 14px    ;">Las contraseñas no coinciden.</small></div>

                                        </div>
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <img src="assets/images/camp/papa-mama.png" class="avatar-lg" alt="madre y padres">
                                    <h4 class="ms-3 mt-3">{{textos[idoma]["titulo"]}}</h4>
                                    <h5 class="mt-2">{{textos[idoma]["texto1"]}}
                                    </h5>
                                </div>
        
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for=""
                                            class="col-sm-12 col-form-label">{{textos[idoma]["texto2"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName="tutor_name" #tutor_name
                                            (input)=" getTutor_name()">
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label" >{{textos[idoma]["texto3"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName="tutor_lastname_father" (input)="getTutor_lastname_father()" #tutor_lastname_father>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto4"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName="tutor_lastname_mother" (input)="gettutor_lastname_mother()" #tutor_lastname_mother>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-4">
                                        <label for="tutor_cellphone" class="col-sm-12 col-form-label">{{textos[idoma]["texto5"]}}</label>
                                        <div class="col-sm-12 ">
                                          <div class="row">
                                            <div class=" col-4">
                                              <select class="form-select" id="country" formControlName="country" >
                                                <option *ngFor="let pais of paisesLatinoamerica" [value]="pais.lada">{{ pais.nombre }}</option>
                                              </select>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" id="tutor_cellphone" formControlName="tutor_cellphone" (input)="gettutor_cellphone()"  #tutor_cellphone>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto6"]}}</label>
                                        <div class="row">
                                            <div class=" col-4">
                                                <select class="form-select" id="country" formControlName="country" >
                                                  <option *ngFor="let pais of paisesLatinoamerica" [value]="pais.lada">{{ pais.nombre }}</option>
                                                </select>
                                              </div>
                                              <div class="col-8">
                                                <div class="col-sm-12">
                                                    <input type="text" class="form-control" id="" formControlName = "tutor_home_phone" #tutor_home_phone (input)="gettutor_home_phone()" >
                                                </div>
                                              </div>
                                        </div>
                                       
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto7"]}}</label>

                                        <div class="row">
                                            <div class="col-4">
                                                <select class="form-select" id="country" formControlName="country" >
                                                    <option *ngFor="let pais of paisesLatinoamerica" [value]="pais.lada">{{ pais.nombre }}</option>
                                                  </select>
                                            </div>
                                            <div class="col-8">
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName = "tutor_work_phone" #tutor_work_phone (input)="gettutor_work_phone()">
                                        </div>
                                            </div>
                                        </div>
                                        
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-12">
                        <div class="card">
        
                            <div class="card-body">
                                <div class="container-footer-parents">
                                    <img src="assets/images/camp/papa-mama.png" class="avatar-lg" alt="madre y padres">
                                    <h4 class="ms-3 mt-3">{{textos[idoma]["texto8"]}}</h4>
                                </div>
        
                                <h5 class="mt-4">{{textos[idoma]["texto9"]}}
        
                                </h5>
        
        
        
        
                                <div class="mt-5">
                                    <div class="row mb-4">
                                        <label for=""
                                            class="col-sm-12 col-form-label">{{textos[idoma]["texto2"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName = "contact_name" #contact_name (input)="getcontact_name()" >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto3"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName = "contact_lastname_father" #contact_lastname_father  (input)="getcontact_lastname_father()">
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto4"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id=""  formControlName = "contact_lastname_mother" #contact_lastname_mother (input)="getcontact_lastname_mother()" >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto5"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id=""  formControlName = "contact_cellphone" (input)="getcontact_cellphone()" #contact_cellphone >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto7"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName = "contact_work_phone" (input)="getcontact_work_phone()" #contact_work_phone >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for="" class="col-sm-12 col-form-label">{{textos[idoma]["texto6"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName ="contact_home_phone" (input)="getcontact_home_phone()" #contact_home_phone >
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <label for=""
                                            class="col-sm-12 col-form-label">{{textos[idoma]["texto10"]}}</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="" formControlName="contact_email" #contact_email (input)="getcontact_email()">
                                        </div>
                                    </div>
                                  
                                    
        
                                </div>
                            </div>
        
                        </div>
                    </div>
        
        
                    <div class="row">
                        <div class=col-lg-12>
                            <div class="card p-5">
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="terms" height="40px" formControlName = "terms"  >
                                        <label class="form-check-label fs-3 ms-3" for="terms">
                                            {{textos[idoma]["texto12"]}}
                                        </label>
                                        <label class="form-check-label fs-3 ms-3" for="terms">
                                            <a
                                                href="https://demo.campercontrol.com/media/uploads/files/terminos-y-condiciones.pdf">
                                                {{textos[idoma]["texto13"]}}</a>
                                        </label>
        
                                    </div>
                                </div>
                                <p class="fs-5">{{textos[idoma]["texto14"]}} <span> {{textos[idoma]["texto15"]}}</span>{{textos[idoma]["texto16"]}}</p>
        
                                <div class="btn-footer">
                                    <button class="btn btn-secondary w-lg btn-lg" (click)="cancelar()">{{textos[idoma]["texto18"]}}</button>
                                    <div >
                                        <button class="btn btn-info w-lg btn-lg"  (click)="prueba()" *ngIf="!spinner">{{textos[idoma]["texto17"]}}</button>
                                        <div class="spinner-border" role="status" *ngIf="spinner">
                                            <span class="visually-hidden">Loading...</span>
                                          </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        
            <!-- end row -->
        </div>
    </div>
  
      <!-- footer -->
    </div>
  </div>


<ng-template #centerDataModal let-modal>
    <div class="modal-header">
      
    </div>
    <div class="modal-body">
        <div class="text-center mb-4">
            <div class="avatar-md mx-auto mb-4">
                <div class="avatar-title bg-light rounded-circle text-success h1">
                    <i class="mdi mdi-account-multiple-check"></i>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <h4 class="text-success">Ya estas Registrado Felicidades !</h4>
                    <p class="text-muted font-size-14 mb-4">
                    </p>

                   

                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- 
    <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <p class="text-muted">Center modal</p>
    
      <button type="button" class="btn btn-primary" (click)="centerModal()">Center
        modal</button>
    </div>
  </div>
-->


