<div class="container-fluid" *ngIf="!cargando">
    <div class="row">
        <div class="mb-4 col-12 d-flex justify-content-center justify-content-lg-end align-items-lg-center">
            <div class="d-flex flex-column d-sm-block med-button-container">
                <button class="ms-3 my-2 my-lg-0 btn btn-primary">Regresar al módulo Médico</button>               
                    <button class="btn btn-primary ms-3 my-2 my-lg-0" (click)="redireccion()" >Agregar consulta</button>
               
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="row border-bottom pb-3">
                <div class="col-12 d-flex justify-content-between">
                    <h2>Información Médica</h2>
                    <div class="dropdown-icon-container">
                        <i class="bx bx-chevron-down"(click)="showConsult=!showConsult"></i>
                    </div>
                </div>
            </div>
            <div class="row box-camp-info" *ngIf="showConsult">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Datos Generales</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Nombre: </dt>
                                    <dd>{{infoCamper.camper.name}} {{infoCamper.camper.lastname_father}} {{infoCamper.camper.lastname_mother}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Peso: </dt>
                                    <dd>{{infoCamper.camper.weight}}kg</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Altura: </dt>
                                    <dd>{{infoCamper.camper.height}}cm</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Enfermedades: </dt>
                                    <dd>{{infoCamper.camper.affliction}}</dd>
                                                                  </div>
                                <div class="my-2">
                                    <dt>Tipo de sangre: </dt>
                                    <dd>{{InfoBlood(infoCamper.camper.blood_type)}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Problemas cardiácos: </dt>
                                    <dd>{{infoCamper.camper.heart_problems}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Tratamientos psicológicos: </dt>
                                    <dd>{{infoCamper.camper.psicology_treatments}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Vacunas: </dt>
                                        <dd *ngFor="let item of this.infoCamper.vaccines" ><div style="margin-top: 10px;"> <p-tag [value]="item.name" *ngIf="item.is_active" ></p-tag> </div><dd>


                                </div>
                         
                            </dl>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Alergias</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Alergias: </dt>
                                    <dd>de {{infoCamper.camper.name}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Medicamentos: </dt>
                                    <dd>{{infoCamper.camper.drug_allergies}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Otras: </dt>
                                    <dd>{{infoCamper.camper.other_allergies}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Alimentación</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Alimentación: </dt>
                                    <dd>de {{infoCamper.camper.name}}</dd>

                                </div>
                                <div class="my-2">
                                    <dt>Alimentación especial: </dt>
                                    <dd *ngFor="let item of infoCamper.food_restrictions" ><div style="margin-top: 10px;"> <p-tag [value]="item.name" *ngIf="item.is_active" ></p-tag> </div><dd>
                                    <dd *ngIf="infoCamper.food_restrictions.length == 0">Ninguna</dd>
                              </div>
                                <div class="my-2">
                                    <dt>Alimentación prohibida: </dt>
                                    <dd>{{infoCamper.camper.prohibited_foods}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Medicación</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Medicamentos: </dt>
                                    <dd>de {{infoCamper.camper.name}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Medicamentos que toma: </dt>
                                    <dd>{{infoCamper.camper.drugs}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Lesiones anteriores: </dt>
                                    <dd>{{infoCamper.camper.prevent_activities}}</dd>
                                </div>
                               
                                <div class="my-2">
                                    <dt>Antecedentes camper: </dt>
                            
                                    
                                    <dd *ngFor="let item of infoCamper.pathological_background" ><div style="margin-top: 10px;"> <p-tag [value]="item.name" *ngIf="item.is_active" ></p-tag> </div><dd>
                                        <dd *ngIf="infoCamper.pathological_background.length == 0">Ninguna</dd>
                                
                                
                                </div>
                                <div class="my-2">
                                    <dt>Antecedentes familiares: </dt>
                      
                                    
                                    <dd *ngFor="let item of infoCamper.pathological_background_fm" ><div style="margin-top: 10px;"> <p-tag [value]="item.name" *ngIf="item.is_active" ></p-tag> </div><dd>
                                        <dd *ngIf="infoCamper.pathological_background_fm.length == 0">Ninguna</dd>
                                
                                </div>
                                <div class="my-2">
                                    <dt>  Medicamentos preautorizados en : </dt>
                      
                                    
                                    <dd *ngFor="let item of infoCamper.licensed_medicines" ><div style="margin-top: 10px;"> <p-tag [value]="item.name" *ngIf="item.is_active" ></p-tag> </div><dd>
                                        <dd *ngIf="infoCamper.licensed_medicines.length == 0">Ninguna</dd>
                                
                                </div>
                            </dl>
                        </div>
                    </div>

                    
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Titular del camper</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Nombre completo: </dt>
                                    <dd>{{infoParent.tutor_name}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono celular: </dt>
                                    <dd>{{infoParent.tutor_cellphone}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono de casa: </dt>
                                    <dd>{{infoParent.tutor_home_phone}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono de trabajo: </dt>
                                    <dd>{{infoParent.tutor_work_phone}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="info-container p-3 mb-5">
                            <h4>Segundo titular del camper</h4>
                            <hr>
                            <dl>
                                <div class="my-2">
                                    <dt>Nombre completo: </dt>
                                    <dd>{{infoParent.contact_name}} {{infoParent.contact_lastname_father}}  {{infoParent.contact_lastname_mother}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono celular: </dt>
                                    <dd>{{infoParent.contact_cellphone}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono de casa: </dt>
                                    <dd>{{infoParent.contact_home_phone}}</dd>
                                </div>
                                <div class="my-2">
                                    <dt>Teléfono de trabajo: </dt>
                                    <dd>{{infoParent.contact_work_phone}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="info-container p-3 mb-5">
                                <h4>Contacto de emergencia</h4>
                                <hr>
                                <dl>
                                    <div class="my-2">
                                        <dt>Nombre completo: </dt>
                                        <dd>{{infoCamper.camper.contact_name}}</dd>
                                    </div>
                                    <div class="my-2">
                                        <dt>Parentesco: </dt>
                                        <dd>{{infoCamper.camper.contact_relation}}</dd>
                                    </div>
                                    <div class="my-2">
                                        <dt>Télefono celular: </dt>
                                        <dd>{{infoCamper.camper.contact_cellphone}}</dd>
                                    </div>
                                    <div class="my-2">
                                        <dt>Télefono de casa: </dt>
                                        <dd>{{infoCamper.camper.contact_homephone}}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-5 mb4 container-consulta card" *ngFor="let item of medicalConsult ;let i = index">
        <div class="container-consultas p-2 rounded mb-2 bg-primary ">

           
            <div>
                <h5 class="text-white">
                    Consulta y el por que fue {{ item.attention_date}} fecha y hora de {{ item.attention_time}}
                </h5>
            </div>
            <div>
                <button class="btn btn btn-warning" (click)="redireccionId(item.id) ">
                    Agregar consulta de seguimiento
                </button>
                <button class="btn btn-light ms-2" 		(click)="medicalConsult[i].show=!medicalConsult[i].show"
               >
               {{ item.show ? '-' : '+' }}

                </button>
            </div>

        </div>
       

        <div class=" p-5" *ngIf="medicalConsult[i].show">
    
            <p-tabView>
                <p-tabPanel header="Consuta inicial">
                    <div class="col-12 " style="margin-top: 30px;">
                        <p class="text-center fs-4 fw-bolder">Diagnóstico : Pequeña contusion en la parte del cranio por
                            caida
                            de niños corriendo</p>
        
                        <p class="text-center fs-4 fw-bold mt-4">Medico Responsable : {{item.doctor}}</p>
        
                    </div>
                    <div>
                        <div class="row mt-5">
                            <div class="col-6 fs-4">
                                <p> Fecha y hora de atencion</p>
        
                            </div>
                            <div class="col-6 fs-4 ">
                                <p>{{item.attention_date}} {{item.attention_time}} </p>
                            </div>
        
                            <hr>
                        </div>
        
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> Descripcion basica para personal no medico</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.description}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> Descripción del evento en palabras del camper </p>
        
                            </div>
                            <div class="col-6 fs-6 ">
                                <p>{{item.event_description}}</p>
        
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> ¿Quién autorizó el medicamento?</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{getAuthorizationName(item.medication_authorization)}}</p>
                                                </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>tratamientos </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.administered_medications}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Restricciones o medidas dentro del campamento</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.camp_restriction}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Recomendaciones para seguimiento posterior al campamento</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.medical_monitoring}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Comentario de la consulta </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.medical_comment}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Comentario solo para medicos y staff
                                </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{item.comment}}</p>
                            </div>
        
                            <hr>
                        </div>
        
                    </div>            </p-tabPanel>
                <p-tabPanel *ngFor="let seguimiento of item.tracing_visits" header="Consulta de seguimiento">
                    <div class="col-12 " style="margin-top: 30px;">
                        <p class="text-center fs-4 fw-bolder">Diagnóstico : Pequeña contusion en la parte del cranio por
                            caida
                            de niños corriendo</p>
        
                        <p class="text-center fs-4 fw-bold mt-4">Medico Responsable : {{seguimiento.doctor}}</p>
        
                    </div>
                    <div>
                        <div class="row mt-5">
                            <div class="col-6 fs-4">
                                <p> Fecha y hora de atencion</p>
        
                            </div>
                            <div class="col-6 fs-4 ">
                                <p>{{seguimiento.attention_date}} {{seguimiento.attention_time}} </p>
                            </div>
        
                            <hr>
                        </div>
        
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> Descripcion basica para personal no medico</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.description}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> Descripción del evento en palabras del camper </p>
        
                            </div>
                            <div class="col-6 fs-6 ">
                                <p>{{seguimiento.event_description}}</p>
        
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p> ¿Quién autorizó el medicamento?</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{getAuthorizationName(seguimiento.medication_authorization)}}</p>
                                                </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>tratamientos </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.administered_medications}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Restricciones o medidas dentro del campamento</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.camp_restriction}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Recomendaciones para seguimiento posterior al campamento</p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.medical_monitoring}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Comentario de la consulta </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.medical_comment}}</p>
                            </div>
        
                            <hr>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6 fs-6">
                                <p>Comentario solo para medicos y staff
                                </p>
        
                            </div>
                            <div class="col-6 fs-6">
                                <p>{{seguimiento.comment}}</p>
                            </div>
        
                            <hr>
                        </div>
        
                    </div>   
                </p-tabPanel>
              
            </p-tabView>
        </div>



    </div>
    

</div>

<div class="alert alert-warning d-flex align-items-center" role="alert" *ngIf="cargando">
    <div class="spinner-border mr-2" role="status"></div>
    <div>Permitanos, estamos cargando la información</div>
  </div>
