<div class="container-fluid">
    <div class="row">
        <div class="col-12">
           

            <form [formGroup]="chekpoint">
            <div class="card border border-success border-1">
                <div class="bg-success p-2">
                    <h1 class="card-title text-white">Agregar un punto de control</h1>
                </div>
                <div class="container-fluid col-10 mt-4 mb-2">
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-sm-5 d-flex justify-content-end col-form-label"> Nombre
                            del punto de control:</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control d-flex justify-content-start" id="inputPassword" formControlName="name">
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputPassword" class="col-sm-5 d-flex justify-content-end col-form-label"> Fecha de
                            aplicacion: </label>
                        <div class="col-sm-4">
                            <input type="date" class="form-control d-flex justify-content-start" formControlName="chekpoint_date">
                        </div>
                    </div>
                    <div class="alert alert-primary" role="alert" *ngIf="letrero">
                         Se agrego correctamente 
                      </div>

                </div>

                <hr class="mb-4">
                <div class="display-flex p-2">
                    <button class="btn btn-secondary" type="submit">ver camp</button>
                    <button class="btn btn-primary" type="submit" (click)="crearCheck()" [disabled]="chekpoint.invalid">Agregar punto de Control</button>
                </div>

            </div>
        </form>


            <div class="card border border-primary border-1">
                <div class="bg-primary p-2">
                    <h1 class="card-title text-white">Acampadores</h1>
                </div>
                <hr>
                <div class=" scroll-y" style="margin:0 auto  ;width: 100%;" >
                  
                    <div class="card-body" *ngIf="!delteStatus">
                        <div>
                            <div class="col text-end">

                            </div>
                        </div>
                        <div class="container-btn">

                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Global Search" />
                        </div>

                        <p-table #dt [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10"
                            [value]="customer" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                            [loading]="loading" [paginator]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0"
                            [globalFilterFields]="['camper.id','camper.camper_name','representative.name','status']">

                            <ng-template pTemplate="header">
                                <tr >

                                    <th style="width: 20px;">
                                        #
                                    </th>
                                    <th style="min-width: 240px; width: 240px;">
                                        Nombre
                                    </th>
                                    <th  *ngFor="let item of columnas">
                                        <div  style="min-width: 70px; width: 240px; white-space: pre-line;" class="conatiner-th">
                                           <div> {{item.name}} </div>
                                         <div>{{item.chekpoint_date_esp}} <i class="bx bx-highlight" style="margin-left: 5px;"(click)="showDialog(item)"></i> </div> 
                                     <div>  <span><i class="bx bxs-barcode p-1 bg-primary" style="color: white; font-size: 15px; margin-right: 4px;margin-top: 2px; border-radius: 2px;" (click)="showDialog2(item)"></i> <i class="bx bx-x p-1 bg-primary" style="color: white; font-size: 15px; margin-right: 4px;margin-top: 2px; border-radius: 2px;" (click)="delet(item)"></i>1 de 5 </span>   </div>  
                                        </div>
                                      

                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-customer>
                                <tr class="p-selectable-row">
                                    <td (click)="hypervinculo(customer.camper.camper_id)">
                                       {{customer.camper.camper_id}}
                                    </td>

                                    <td style="text-align: left; font-size: 10px;">
                                        {{customer.camper.camper_name}}
                                    </td>
                                    <td style="text-align: left; font-size: 10px;" *ngFor="let b  of customer.checkpoints" [ngClass]="{'acierto':b.checkpoint_check,'error':!b.checkpoint_check}">
                                        <div *ngIf="b.checkpoint_date"  style="">
                                            {{b.checkpoint_date}}
                                        </div>
                                        <div *ngIf="!b.checkpoint_check" style="">

                                        </div>
                                       
                                        
                                    </td>
                                 
                                  
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">

                            </ng-template>
                        </p-table>

                    </div>
                    <div class="spinner-border text-primary p-2" role="status" *ngIf="delteStatus" style="margin: auto ;">
                        <span  style="margin: auto ;"class="sr-only">Loading...</span>
                      </div>
                </div>

            </div>
        </div>
    </div>
</div>

<p-dialog header="Editar" [(visible)]="visible" [style]="{width: '50vw'}">
    <div class="container-fluid col-10 mt-4 mb-2">
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-5 d-flex justify-content-end col-form-label"> Nombre
                del punto de control:</label>
            <div class="col-sm-7">
                <input type="text" class="form-control d-flex justify-content-start" id="inputPassword" [(ngModel)]="updateName">
            </div>
        </div>
        <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-5 d-flex justify-content-end col-form-label"> Fecha de
                aplicacion: </label>
            <div class="col-sm-4">
                <input type="date" class="form-control d-flex justify-content-start" [(ngModel)]="updateDate">
            </div>
        </div>
        

    </div>

    <hr class="mb-4">
    <div class="display-flex p-2">
        <button class="btn btn-secondary" type="submit" (click)="cerrarModal()">Cancelar</button>
        <button class="btn btn-primary" type="submit" (click)="updadte()" *ngIf="!estatusUpdate">Actualizar </button >
        <div class="spinner-border text-primary" role="status" *ngIf="estatusUpdate">
            <span class="visually-hidden">Loading...</span>
          </div>
    </div>
</p-dialog>


<p-dialog [header]="namePunto" [(visible)]="visibleCreate" [style]="{width: '50vw'}">
    <div class="container-fluid col-10 mt-4 mb-2">
        <div class="mb-3 row">
            <div class="col-sm-9">      
                <div>
                    <label for="inputPassword" >Inscribir a punto de control:</label>

               
                    <input type="number" #miInput  class="form-control" id="inputPassword" [(ngModel)]="inscribirPunto"  (keyup.enter)="Inscribe()">

                </div>
            </div>
            <div class="col-3 " style="margin-top: 30px;">
                <form (submit)="Inscribe()">
                    <button type="submit" class="btn btn-primary m" style="width: 90%;">Agregar</button>
                  </form>            </div>
        </div>

        <div *ngIf="cuadroMensaje">
            <div class="alert alert-primary" role="alert" *ngIf="mensajeActivo">
                Se agrego correctamente  {{this.nameCamper}}
              </div>

              <div class="alert alert-danger" role="alert" *ngIf="mensajeerror">
                No se pudo agregar(id no se encontro)
              </div>

        </div>
    </div>

    <hr class="mb-4">
    <div class="display-flex p-2">
        <div class="spinner-border text-primary" role="status" *ngIf="estatusUpdate">
            <span class="visually-hidden">Loading...</span>
          </div>
    </div>
</p-dialog>